/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var EngagingIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.0001 0.699951V9.87995H22.2901L17.2101 14.96L15.7901 13.54L17.4601 11.88H14.0001V5.29995L6.67008 12.13H10.0001V18.8899L12.4901 16.85L13.7601 18.4L8.00008 23.11V14.13H1.58008L16.0001 0.699951ZM14.0186 15.8021C14.0565 15.6083 14.151 15.4301 14.29 15.2899C14.383 15.1962 14.4936 15.1218 14.6154 15.071C14.7373 15.0203 14.868 14.9941 15 14.9941C15.132 14.9941 15.2627 15.0203 15.3846 15.071C15.5064 15.1218 15.617 15.1962 15.71 15.2899C15.849 15.4301 15.9435 15.6083 15.9814 15.8021C16.0193 15.9958 15.999 16.1965 15.9231 16.3787C15.8472 16.561 15.719 16.7167 15.5547 16.8262C15.3904 16.9357 15.1974 16.9941 15 16.9941C14.8026 16.9941 14.6096 16.9357 14.4453 16.8262C14.281 16.7167 14.1528 16.561 14.0769 16.3787C14.001 16.1965 13.9807 15.9958 14.0186 15.8021Z",
    fill: "none"
  })
}), 'EngagingIcon', 'Brandbook');
export default EngagingIcon;