/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UserHeartIcon = createIconsComponent(_jsxs("svg", {
  width: 33,
  height: 32,
  viewBox: "0 0 33 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M2.99553 24.6888C2.51108 24.6888 2.11108 24.2932 2.11108 23.7999C2.11108 21.8799 2.66664 20.0221 3.72886 18.4266C4.79108 16.8266 6.28441 15.591 8.0533 14.8443C9.82218 14.0977 11.7511 13.8977 13.6355 14.2532C15.52 14.6132 17.24 15.511 18.6088 16.8532C18.9599 17.1955 18.9644 17.7599 18.6222 18.111C18.28 18.4621 17.7155 18.4666 17.3644 18.1243C16.2444 17.0266 14.84 16.2932 13.3022 15.9999C11.7644 15.7066 10.1866 15.8755 8.74219 16.4799C7.29774 17.0888 6.07552 18.0977 5.20886 19.4043C4.34219 20.711 3.88441 22.2266 3.88886 23.7954C3.88886 24.2843 3.4933 24.6843 2.99997 24.6843L2.99553 24.6888ZM11.8266 13.9021C8.35996 13.9021 5.54219 11.0843 5.54219 7.61769C5.54219 4.15103 8.36441 1.33325 11.8266 1.33325C15.2888 1.33325 18.1111 4.15103 18.1111 7.61769C18.1111 11.0843 15.2933 13.9021 11.8266 13.9021ZM11.8266 3.11103C9.34219 3.11103 7.31997 5.13325 7.31997 7.61769C7.31997 10.1021 9.34219 12.1243 11.8266 12.1243C14.3111 12.1243 16.3333 10.1021 16.3333 7.61769C16.3333 5.13325 14.3111 3.11103 11.8266 3.11103Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.6205 19.8315C19.0701 19.6489 19.5528 19.5554 20.0393 19.5554C20.5258 19.5554 21.0085 19.6489 21.4581 19.8315C21.9052 20.0131 22.312 20.2796 22.6524 20.6177L23.6666 21.5964L24.6808 20.6177C25.0212 20.2796 25.4281 20.0131 25.8751 19.8315C26.3248 19.6489 26.8074 19.5554 27.2939 19.5554C27.7804 19.5554 28.2631 19.6489 28.7127 19.8315C29.1611 20.0136 29.569 20.2812 29.9101 20.6207C30.6031 21.3014 30.9999 22.2188 30.9999 23.1844C30.9999 24.1513 30.602 25.0701 29.907 25.7511L29.9047 25.7533L24.9237 30.6011C24.7568 30.7653 24.5593 30.8931 24.3447 30.9797C24.1288 31.0668 23.8983 31.111 23.6666 31.111C23.4349 31.111 23.2044 31.0668 22.9885 30.9797C22.7739 30.8931 22.5765 30.7653 22.4095 30.6011L17.4285 25.7533L17.4262 25.7511C16.7312 25.0701 16.3333 24.1513 16.3333 23.1844C16.3333 22.2188 16.7301 21.3013 17.4232 20.6207C17.7642 20.2812 18.1721 20.0136 18.6205 19.8315ZM20.0393 21.4336C19.7946 21.4336 19.5538 21.4807 19.3321 21.5708C19.1104 21.6608 18.9143 21.791 18.7543 21.9507L18.7482 21.9567C18.402 22.2959 18.2185 22.7368 18.2185 23.1844C18.2185 23.6315 18.4017 24.072 18.7472 24.411L18.7482 24.4121L23.6666 29.1989L28.585 24.4121L28.5861 24.411C28.9316 24.072 29.1147 23.6315 29.1147 23.1844C29.1147 22.7368 28.9312 22.2959 28.585 21.9567L28.5789 21.9507C28.4189 21.791 28.2228 21.6608 28.0012 21.5708C27.7794 21.4807 27.5386 21.4336 27.2939 21.4336C27.0493 21.4336 26.8084 21.4807 26.5867 21.5708C26.365 21.6608 26.169 21.791 26.0089 21.9507L25.9977 21.9617L24.3224 23.5783C23.9569 23.9311 23.3763 23.9311 23.0108 23.5783L21.3355 21.9617L21.3243 21.9507C21.1643 21.791 20.9682 21.6608 20.7465 21.5708C20.5248 21.4807 20.284 21.4336 20.0393 21.4336Z",
    fill: "none"
  })]
}), 'UserHeartIcon', 'Brandbook');
export default UserHeartIcon;