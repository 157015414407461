/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RaiseIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.93 16.48L6.93 10.48L4 13.41V20H18V22H2V3H4V10.59L7.12 7.47L12.12 13.47L15.12 10.47L11.71 7.05L22.1 4.27L19.31 14.66L16.53 11.88L11.93 16.48ZM18.27 10.8L19.27 7.1L15.57 8.09L18.27 10.8Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22 21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21C20 20.4477 20.4477 20 21 20C21.5523 20 22 20.4477 22 21Z",
    fill: "none"
  })]
}), 'RaiseIcon', 'Brandbook');
export default RaiseIcon;