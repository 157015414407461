/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var EmptyStateIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.71 19.29L19.08 16.66C20.0898 15.4011 20.7491 13.8978 20.9912 12.3022C21.2332 10.7067 21.0494 9.07546 20.4584 7.57375C19.8674 6.07204 18.8901 4.75312 17.6255 3.75051C16.361 2.74791 14.8539 2.09719 13.257 1.86424C11.6601 1.63129 10.0299 1.82438 8.53163 2.42395C7.03331 3.02353 5.72 4.00831 4.72462 5.27861C3.72925 6.54891 3.08715 8.05963 2.86331 9.65786C2.63948 11.2561 2.84187 12.8851 3.44999 14.38L5.29999 13.63C4.68437 12.1121 4.60777 10.4291 5.08296 8.86163C5.55814 7.29416 6.55639 5.93699 7.91119 5.0165C9.26599 4.09601 10.8954 3.66785 12.5277 3.80344C14.16 3.93902 15.6965 4.63016 16.8809 5.76157C18.0652 6.89298 18.8258 8.39625 19.0359 10.0206C19.2459 11.645 18.8927 13.2924 18.0351 14.6878C17.1775 16.0833 15.8674 17.1425 14.3232 17.6888C12.7791 18.2352 11.0944 18.2356 9.54999 17.69L8.87999 19.57C9.84354 19.9111 10.8578 20.0869 11.88 20.09C13.9677 20.0928 15.9947 19.3877 17.63 18.09L20.26 20.72L21.71 19.29ZM5.29 16.2899C5.15097 16.4301 5.05654 16.6083 5.01862 16.8021C4.9807 16.9958 5.00098 17.1965 5.07691 17.3787C5.15283 17.561 5.28101 17.7167 5.44528 17.8262C5.60955 17.9357 5.80257 17.9941 6 17.9941C6.19743 17.9941 6.39045 17.9357 6.55472 17.8262C6.71899 17.7167 6.84717 17.561 6.92309 17.3787C6.99902 17.1965 7.0193 16.9958 6.98138 16.8021C6.94346 16.6083 6.84903 16.4301 6.71 16.2899C6.61704 16.1962 6.50644 16.1218 6.38458 16.071C6.26272 16.0203 6.13201 15.9941 6 15.9941C5.86799 15.9941 5.73728 16.0203 5.61542 16.071C5.49356 16.1218 5.38296 16.1962 5.29 16.2899ZM11.9999 12.3199L9.82992 14.4899L8.41992 13.0799L10.5899 10.9099L8.50992 8.82992L9.91992 7.41992L11.9999 9.48992L14.0799 7.41992L15.4899 8.82992L13.4099 10.9099L15.5799 13.0799L14.1699 14.4899L11.9999 12.3199Z",
    fill: "none"
  })
}), 'EmptyStateIcon', 'Brandbook');
export default EmptyStateIcon;