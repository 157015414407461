/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityTakingIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12.3342 10C12.8865 10 13.3342 9.55228 13.3342 9C13.3342 8.44772 12.8865 8 12.3342 8C11.7819 8 11.3342 8.44772 11.3342 9C11.3342 9.55228 11.7819 10 12.3342 10Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.5537 19.6247L13.0515 11.4969L14.6133 10.2476L21.1155 18.3754L19.5537 19.6247Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.85352 5H2.33423V3H8.81494L11.2562 6.05241L9.69427 7.30159L7.85352 5Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.3352 19V15H22.3352V21H16.3352V19H20.3352Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M3.33325 21V9H5.33325V21H3.33325Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.33521 21V13H9.33521V21H7.33521Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.3342 21V17H13.3342V21H11.3342Z",
    fill: "none"
  })]
}), 'SustainabilityTakingIcon', 'Brandbook');
export default SustainabilityTakingIcon;