/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApiIcon = createIconsComponent(_jsxs("svg", {
  width: 23,
  height: 24,
  viewBox: "0 0 23 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.333 1h2v1.17c.351.125.673.312.95.55l1.014-.586 1 1.732-1.014.586a3.013 3.013 0 010 1.096l1.014.586-1 1.732-1.014-.586a2.997 2.997 0 01-.95.55V9h-2V7.83a2.996 2.996 0 01-.95-.55l-1.014.586-1-1.732 1.014-.586a3.013 3.013 0 010-1.096l-1.014-.586 1-1.732 1.015.586c.277-.238.598-.425.95-.55V1zm-.5 4a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z",
    fill: "none"
  }), _jsx("path", {
    d: "M2.333 3h11v2h-9v2h-2V3zM4.333 15h16v-4h2v6h-9v2h4v2h-10v-2h4v-2h-9v-4h2v2zM4.333 10a1 1 0 11-2 0 1 1 0 012 0z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.326 13h-.872l1.348-4.103h1.21L9.345 13h-.97l-.28-.912H6.606L6.326 13zm1.566-1.572l-.539-1.773-.545 1.773h1.084zM10.829 13h-.924V8.897h1.417c.998 0 1.566.442 1.566 1.309v.023c0 .866-.602 1.28-1.537 1.28h-.523V13zm0-3.397v1.257h.47c.453 0 .694-.218.694-.626v-.023c0-.436-.252-.608-.694-.608h-.47z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.455 13h-.936V8.897h.936V13z",
    fill: "none"
  })]
}), 'ApiIcon', 'Brandbook');
export default ApiIcon;