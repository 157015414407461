/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CheckIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.86 7.71004L20.28 6.29004L23.19 9.20004L11.03 22.44L0.780029 12.23L5.73003 7.28004L10.95 12.54L14.84 8.10004L16.34 9.41004L11.05 15.46L5.73003 10.11L3.61003 12.23L10.97 19.56L20.42 9.26004L18.86 7.71004Z",
    fill: "none"
  })]
}), 'CheckIcon', 'Brandbook');
export default CheckIcon;