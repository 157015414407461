/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LaunchIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.94 2.41992L22.5 1.41992L21.6 8.04992L16.92 12.7899L14.92 23.0699L9.35999 17.4999L16.43 10.1499L13.87 7.63992L6.49999 14.6399L0.929993 9.07992L11.21 7.12992L15.94 2.41992ZM6.50999 11.8299L8.69999 9.63992L4.99999 10.3399L6.50999 11.8299ZM12.21 17.4899L13.7 18.9799L14.4 15.2999L12.21 17.4899ZM18 8.82992L19.71 7.11992L20.16 3.75992L16.89 4.24992L15.14 5.99992L18 8.82992Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4 21C4.55228 21 5 20.5523 5 20C5 19.4477 4.55228 19 4 19C3.44772 19 3 19.4477 3 20C3 20.5523 3.44772 21 4 21Z",
    fill: "none"
  })]
}), 'LaunchIcon', 'Brandbook');
export default LaunchIcon;