/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var GraphIcon = createIconsComponent(_jsx("svg", {
  width: 33,
  height: 32,
  viewBox: "0 0 33 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.593 1.866a.889.889 0 0 1 1.089-.628l3.844 1.03a.889.889 0 0 1 .629 1.088l-1.03 3.845a.889.889 0 0 1-1.718-.46l.483-1.801-16.163 9.116a.889.889 0 0 1-.873-1.548l16.11-9.086-1.742-.467a.889.889 0 0 1-.629-1.089Zm2.581 9.378c.361-.25.801-.362 1.219-.362h4.03c.417 0 .857.113 1.218.362.363.252.678.676.678 1.224v16.547h1.126a.889.889 0 1 1 0 1.778H1.222a.889.889 0 1 1 0-1.778h1.126v-6.127c0-.618.445-.994.765-1.165a2.434 2.434 0 0 1 1.132-.263h4.03c.377 0 .785.078 1.131.263.32.171.764.547.764 1.165v6.127h2.252v-12.51c0-.545.31-.971.675-1.226a2.145 2.145 0 0 1 1.222-.367h4.03c.419 0 .86.114 1.221.367.364.255.675.68.675 1.227v12.51h2.251V12.467c0-.548.315-.972.678-1.224Zm1.1 17.771h4.267V12.673a.525.525 0 0 0-.119-.013h-4.03a.524.524 0 0 0-.118.013v16.342Zm-5.807 0V16.703a.509.509 0 0 0-.119-.014h-4.03a.51.51 0 0 0-.118.014v12.312h4.267Zm-10.074 0v-5.77a.955.955 0 0 0-.119-.008h-4.03a.956.956 0 0 0-.118.008v5.77h4.267Z",
    fill: "none"
  })
}), 'GraphIcon', 'Brandbook');
export default GraphIcon;