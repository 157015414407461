/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var WellnessIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.5299 7.99998C21.837 8.97055 21.9955 9.982 21.9999 11C21.9993 13.4508 21.0987 15.8161 19.469 17.6467C17.8394 19.4773 15.5942 20.6457 13.1599 20.93V21H10.8199C9.35268 20.8275 7.94186 20.332 6.68893 19.5492C5.436 18.7664 4.37208 17.7157 3.57366 16.4727C2.77525 15.2296 2.26218 13.8251 2.07134 12.3601C1.88049 10.8952 2.01662 9.40608 2.46994 7.99998L4.37994 8.57998C4.12979 9.36236 4.00162 10.1786 3.99994 11C3.99927 12.949 4.71013 14.8312 5.99903 16.2932C7.28793 17.7552 9.06621 18.6964 10.9999 18.94C10.9795 16.4502 10.031 14.0575 8.33994 12.23L8.11994 12L7.99994 11C7.99994 6.99998 11.4199 4.31998 11.5899 4.25998L12.2699 3.72998L12.8899 4.32998C14.2787 5.73015 15.308 7.44565 15.8899 9.32998C17.2266 8.36314 18.7483 7.6823 20.3599 7.32998L21.2499 7.12998L21.5299 7.99998ZM9.99994 10.94V11.09C10.8275 12.0175 11.5026 13.0707 11.9999 14.21C12.5132 12.9934 13.2264 11.8712 14.1099 10.89C13.8692 9.24376 13.1769 7.69648 12.1099 6.41998C10.8808 7.62025 10.1307 9.227 9.99994 10.94ZM14.9018 13.1133C13.6699 14.8067 13.0043 16.8459 12.9999 18.94C14.9336 18.6964 16.7119 17.7552 18.0008 16.2932C19.2897 14.8312 20.0006 12.949 19.9999 11C19.9984 10.5001 19.9516 10.0014 19.8599 9.50998C17.8689 10.1588 16.1337 11.4199 14.9018 13.1133Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.85265 7.52361C6.74977 7.69231 6.59922 7.82674 6.42 7.90994C6.30055 7.9654 6.17132 7.99672 6.03972 8.0021C5.90813 8.00747 5.77678 7.9868 5.6532 7.94127C5.52962 7.89574 5.41625 7.82625 5.3196 7.73679C5.22295 7.64732 5.14492 7.53965 5.09 7.41994C5.0076 7.24035 4.98026 7.04038 5.01141 6.84526C5.04256 6.65014 5.13082 6.46863 5.26505 6.32363C5.39927 6.17862 5.57344 6.07663 5.76558 6.03053C5.95771 5.98443 6.1592 5.99628 6.34461 6.06459C6.53001 6.13289 6.69103 6.2546 6.80733 6.41433C6.92363 6.57407 6.99 6.76468 6.99807 6.96211C7.00613 7.15953 6.95553 7.35492 6.85265 7.52361Z",
    fill: "none"
  })]
}), 'WellnessIcon', 'Brandbook');
export default WellnessIcon;