/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityMeasuringIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14.998 20C15.5503 20 15.998 19.5523 15.998 19C15.998 18.4477 15.5503 18 14.998 18C14.4458 18 13.998 18.4477 13.998 19C13.998 19.5523 14.4458 20 14.998 20Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.7634 14.1522C12.398 14.0009 11.9958 13.9613 11.6079 14.0384C11.2199 14.1156 10.8635 14.3061 10.5838 14.5858C10.3041 14.8655 10.1136 15.2219 10.0365 15.6098C9.95931 15.9978 9.99891 16.3999 10.1503 16.7654C10.3017 17.1308 10.558 17.4432 10.8869 17.6629C11.2158 17.8827 11.6025 18 11.998 18V20C11.2069 20 10.4336 19.7654 9.77577 19.3259C9.11797 18.8864 8.60528 18.2616 8.30253 17.5307C7.99978 16.7998 7.92057 15.9956 8.07491 15.2196C8.22925 14.4437 8.61021 13.731 9.16962 13.1716C9.72903 12.6122 10.4418 12.2312 11.2177 12.0769C11.9936 11.9225 12.7979 12.0017 13.5288 12.3045C14.2597 12.6072 14.8844 13.1199 15.3239 13.7777C15.7635 14.4355 15.998 15.2089 15.998 16H13.998C13.998 15.6044 13.8808 15.2178 13.661 14.8889C13.4412 14.56 13.1289 14.3036 12.7634 14.1522Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.998 7V4H12.998V7H10.998Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.31622 9.73034L4.17822 7.73034L5.54451 6.26978L7.68251 8.26978L6.31622 9.73034Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.1782 8.26978L18.3162 6.26978L19.6825 7.73034L17.5445 9.73034L16.1782 8.26978Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.22085 6.22183C6.28375 4.15893 9.08164 3 11.999 3C14.9164 3 17.7143 4.15893 19.7772 6.22183C21.8401 8.28473 22.999 11.0826 22.999 14H20.999C20.999 11.6131 20.0508 9.32387 18.363 7.63604C16.6752 5.94821 14.386 5 11.999 5C9.61207 5 7.32289 5.94821 5.63506 7.63604C4.15149 9.11961 3.23935 11.0678 3.04031 13.1386L9.14044 14.0101L8.8576 15.9899L0.999023 14.8673V14C0.999023 11.0826 2.15795 8.28473 4.22085 6.22183Z",
    fill: "none"
  })]
}), 'SustainabilityMeasuringIcon', 'Brandbook');
export default SustainabilityMeasuringIcon;