/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var IntegrationIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14 4.4C14 3.66488 13.3673 3 12.5 3C11.6327 3 11 3.66488 11 4.4V6H6V11H4.4C3.66488 11 3 11.6327 3 12.5C3 13.3673 3.66488 14 4.4 14H6V22H4V15.9763C2.28146 15.7712 1 14.26 1 12.5C1 10.74 2.28146 9.2288 4 9.02367V4H9.02367C9.2288 2.28146 10.74 1 12.5 1C14.26 1 15.7712 2.28146 15.9763 4H22V11H20.4C19.6649 11 19 11.6327 19 12.5C19 13.3673 19.6649 14 20.4 14H22V22H12V20H20V15.9763C18.2815 15.7712 17 14.26 17 12.5C17 10.74 18.2815 9.2288 20 9.02367V6H14V4.4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z",
    fill: "none"
  })]
}), 'IntegrationIcon', 'Brandbook');
export default IntegrationIcon;