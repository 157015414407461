/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var SpeedometerIcon = createIconsComponent(_jsx("svg", {
  width: 33,
  height: 33,
  viewBox: "0 0 33 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.63 7.292a15.994 15.994 0 0 1 5.98-1.206h.013c4.148.031 8.118 1.667 11.035 4.554 2.918 2.888 4.544 6.792 4.514 10.855v2.645a2.75 2.75 0 0 1-.835 1.969 2.849 2.849 0 0 1-1.988.804H3.884a2.849 2.849 0 0 1-1.988-.804 2.75 2.75 0 0 1-.835-1.97V21.5a14.982 14.982 0 0 1 1.15-5.868 15.271 15.271 0 0 1 3.362-4.99 15.69 15.69 0 0 1 5.057-3.349Zm2.541 17.893h6.401c-.363-1.566-1.69-2.679-3.2-2.679-1.51 0-2.838 1.113-3.2 2.68Zm8.16 0h8.018c.297 0 .578-.115.782-.314.202-.198.312-.461.312-.731v-2.655c.028-3.592-1.409-7.051-4-9.616-2.592-2.565-6.125-4.025-9.826-4.054-1.832.014-3.642.38-5.328 1.075a13.962 13.962 0 0 0-4.5 2.98 13.543 13.543 0 0 0-2.982 4.424 13.254 13.254 0 0 0-1.017 5.194v2.652c0 .27.11.533.313.731.203.199.484.314.781.314h7.529c.39-2.468 2.426-4.407 4.959-4.407.262 0 .518.02.769.06l5.913-7.095a.864.864 0 0 1 1.327 1.107l-5.516 6.619c1.294.777 2.216 2.139 2.466 3.716Zm-4.714-14.691c.477 0 .864.387.864.864v2.938a.864.864 0 0 1-1.729 0v-2.938c0-.477.387-.864.865-.864ZM8.66 13.685a.864.864 0 0 1 1.222 0l2.204 2.204a.864.864 0 1 1-1.223 1.222L8.66 14.907a.864.864 0 0 1 0-1.222Zm-3.681 6.978c0-.478.387-.865.864-.865H8.78a.864.864 0 1 1 0 1.729H5.843a.864.864 0 0 1-.864-.864Zm18.609 0c0-.478.387-.865.864-.865h2.938a.864.864 0 1 1 0 1.729h-2.938a.864.864 0 0 1-.864-.864Z",
    fill: "none"
  })
}), 'SpeedometerIcon', 'Brandbook');
export default SpeedometerIcon;