/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PieChart = createIconsComponent(_jsxs("svg", {
  width: 30,
  height: 32,
  viewBox: "0 0 30 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M28.8841 13.7778H16.8841C16.3952 13.7778 15.9952 13.3778 15.9952 12.8889V0.888918C15.9952 0.40003 16.3952 3.05176e-05 16.8841 3.05176e-05C20.3286 3.05176e-05 23.5641 1.34225 25.9997 3.77336C28.4352 6.20447 29.773 9.44446 29.773 12.8889C29.773 13.3778 29.373 13.7778 28.8841 13.7778ZM17.773 12H27.9597C27.7508 9.36891 26.6263 6.92002 24.7419 5.03113C22.8575 3.14225 20.4086 2.01781 17.773 1.81336V12Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.893 31.7777C11.6619 31.7777 10.4308 31.6043 9.25304 31.2577C7.2886 30.6799 5.47082 29.631 3.99083 28.2177C2.51083 26.8043 1.3775 25.0354 0.706386 23.1066C0.0397204 21.1688 -0.160279 19.0755 0.128609 17.0532C0.417498 15.031 1.19972 13.0799 2.38638 11.4132C3.57305 9.74658 5.15971 8.37325 6.97749 7.43547C8.79526 6.49769 10.8397 6.00436 12.8841 6.00436C13.373 6.00436 13.773 6.40436 13.773 6.89325V18.5866L20.9997 27.7821C21.1464 27.9688 21.213 28.2043 21.1819 28.4354C21.1552 28.671 21.0352 28.8843 20.8486 29.0266C19.2397 30.2888 17.3286 31.1643 15.3197 31.5465C14.5197 31.7021 13.7064 31.7777 12.8886 31.7777H12.893ZM11.9997 7.81325C10.5375 7.93325 9.10193 8.34214 7.79971 9.01325C6.23082 9.82213 4.86194 11.0088 3.83972 12.4444C2.81749 13.8799 2.14194 15.5643 1.89305 17.3066C1.63972 19.0532 1.81305 20.8532 2.39083 22.5199C2.96861 24.1866 3.94638 25.711 5.22194 26.9288C6.49749 28.1466 8.06637 29.0532 9.75971 29.551C11.4486 30.0488 13.2619 30.1332 14.9908 29.8043C16.4308 29.5288 17.813 28.9599 19.0352 28.151L12.1908 19.4443C12.0664 19.2888 11.9997 19.0932 11.9997 18.8932V7.81325Z",
    fill: "none"
  }), _jsx("path", {
    d: "M24.3019 27.2133C24.0397 27.2133 23.7775 27.0978 23.6041 26.8755L16.1908 17.44C15.9819 17.1733 15.9419 16.8089 16.0886 16.5022C16.2353 16.1956 16.5464 16 16.8886 16H28.8886C29.3775 16 29.7775 16.3956 29.7775 16.8889C29.7775 18.8311 29.333 20.7778 28.4841 22.5244C27.6352 24.2711 26.3819 25.8267 24.853 27.0222C24.6886 27.1511 24.4975 27.2133 24.3064 27.2133H24.3019ZM18.7153 17.7778L24.4308 25.0489C25.4441 24.1111 26.2841 22.9867 26.8841 21.7467C27.4841 20.5067 27.853 19.1511 27.9641 17.7778H18.7153Z",
    fill: "none"
  })]
}), 'PieChart', 'Brandbook');
export default PieChart;