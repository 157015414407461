/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UnderstandingIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12 1C10.3534 1.01141 8.75046 1.53066 7.40989 2.48686C6.06933 3.44306 5.05649 4.78964 4.5096 6.34281C3.96271 7.89598 3.90841 9.58007 4.35413 11.1652C4.79984 12.7504 5.72384 14.1594 7.00003 15.2L8.26003 13.65C7.25038 12.8302 6.53444 11.7046 6.22002 10.4426C5.90561 9.18063 6.00974 7.85067 6.51675 6.65302C7.02376 5.45537 7.90618 4.45488 9.03112 3.80223C10.1561 3.14959 11.4626 2.88014 12.754 3.03445C14.0453 3.18877 15.2516 3.75849 16.1911 4.65783C17.1305 5.55716 17.7524 6.7374 17.9629 8.0208C18.1734 9.30419 17.9613 10.6212 17.3583 11.7736C16.7554 12.9259 15.7944 13.8512 14.62 14.41L14.05 14.69V18H9.00003V20H16V15.92C17.5211 15.0377 18.7091 13.6787 19.3802 12.0534C20.0514 10.4281 20.1684 8.62685 19.713 6.92837C19.2576 5.22989 18.2554 3.72876 16.8611 2.65714C15.4669 1.58551 13.7585 1.00311 12 1Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10 16C10 16.5523 9.55228 17 9 17C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15C9.55228 15 10 15.4477 10 16Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14 21V23H10V21H14Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9H14C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7V5C13.0609 5 14.0783 5.42143 14.8284 6.17157Z",
    fill: "none"
  })]
}), 'UnderstandingIcon', 'Brandbook');
export default UnderstandingIcon;