/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SupportIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  viewBox: "0 0 32 32",
  fill: "none",
  children: [_jsx("path", {
    fill: "none",
    fillRule: "evenodd",
    d: "M14.667 8V4h2.666v4h-2.666ZM14.667 28v-4h2.666v4h-2.666ZM24 14.667h4v2.666h-4v-2.666ZM4 14.667h4v2.666H4v-2.666ZM22.535 10.182l-6.317 7.648-5.018-3.763 1.6-2.134 2.982 2.237 4.697-5.686 2.056 1.698Z",
    clipRule: "evenodd"
  }), _jsx("path", {
    fill: "none",
    d: "M23.333 7.84a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
  }), _jsx("path", {
    fill: "none",
    fillRule: "evenodd",
    d: "M8.592 4.914A13.333 13.333 0 0 1 16 2.667h.006c1.539.006 3.065.276 4.513.799l-.905 2.508a10.8 10.8 0 0 0-3.617-.64A10.667 10.667 0 1 0 26.667 16v-.002a10.533 10.533 0 0 0-1.55-5.515L27.39 9.09a13.2 13.2 0 0 1 1.943 6.912L28 16h1.333v.002A13.334 13.334 0 1 1 8.593 4.914Z",
    clipRule: "evenodd"
  })]
}), 'SupportIcon', 'Brandbook');
export default SupportIcon;