/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DriveIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13 13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.7068 6.00278C21.1826 7.65195 21.999 9.78704 22 12.0001C21.999 14.2132 21.1826 16.3483 19.7068 17.9974C18.231 19.6466 16.1994 20.6942 14 20.9401V22.0001H6.24001C5.11549 22.0001 4.03703 21.5534 3.24187 20.7582C2.44672 19.9631 2.00001 18.8846 2.00001 17.7601V12.0001H4.00001V17.7601C4.00001 18.3542 4.23601 18.9239 4.65609 19.344C5.07617 19.7641 5.64592 20.0001 6.24001 20.0001H7.00001V4.0001H6.24001C5.64592 4.0001 5.07617 4.2361 4.65609 4.65619C4.23601 5.07627 4.00001 5.64602 4.00001 6.2401H2.00001C1.99816 5.14731 2.42131 4.09661 3.18001 3.3101C3.5724 2.89285 4.04672 2.56113 4.57327 2.33571C5.09982 2.11029 5.66725 1.99604 6.24001 2.0001H14V3.0601C16.1994 3.306 18.231 4.35362 19.7068 6.00278ZM12 4.0001H9.00001V7.0001H12V4.0001ZM9.00001 20.0001H12V17.0001H9.00001V20.0001ZM14 15.0001V18.9201C15.4929 18.703 16.8758 18.0095 17.9426 16.9427C19.0094 15.8759 19.7029 14.493 19.92 13.0001H17V11.0001H19.92C19.7029 9.50716 19.0094 8.12429 17.9426 7.05752C16.8758 5.99075 15.4929 5.29717 14 5.0801V9.0001H9.00001V15.0001H14Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3 10C3.55228 10 4 9.55228 4 9C4 8.44772 3.55228 8 3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10Z",
    fill: "none"
  })]
}), 'DriveIcon', 'Brandbook');
export default DriveIcon;