/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CalendarIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.333 2h2v2h10V2h2v2h3v14h-2V6h-16v14h14v2h-16V4h3V2zM21.333 20a1 1 0 110 2 1 1 0 010-2z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.333 10h2v2h-2v-2zM7.333 14h2v2h-2v-2zM13.333 10h-2v2h2v-2zM11.333 14h2v2h-2v-2zM17.333 10h-2v2h2v-2zM15.333 14h2v2h-2v-2z",
    fill: "none"
  })]
}), 'CalendarIcon', 'Brandbook');
export default CalendarIcon;