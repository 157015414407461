/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var CertificateIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.23 15.42L17.08 18.85L16.32 18.73L15.47 18.59L14.79 19.11L14.17 19.57L13.26 15.92L12.71 13.73L12.47 13.67L12 13.55L11.52 13.67L10.25 14L9.41 13L9.1 12.63L8.64 12.44L7.43 11.95L7.34 10.65V10.16L7 9.72L6.35 8.61L7 7.5L7.26 7.08V6.59L7.35 5.29L8.64 4.8L9.1 4.61L9.41 4.24L10.25 3.24L11.52 3.55L12 3.67L12.48 3.55L13.75 3.24L14.59 4.24L14.9 4.61L15.36 4.8L16.57 5.29L16.66 6.59V7.08L17 7.5L17.69 8.61L17 9.72L16.76 10.11L18.76 10.7L20 8.61L18.66 6.45L18.47 3.91L16.11 3L14.47 1L12 1.61L9.53 1L7.89 3L5.53 4L5.34 6.45L4 8.61L5.34 10.77L5.53 13.31L6.09 13.54L4.16 21.31L7.92 20.7L11 23L12 19L13 23L16 20.7L19.76 21.31L18.38 15.77L16.23 15.42ZM9.75 19.57L9.13 19.11L8.45 18.59L7.6 18.73L6.83 18.85L8 14.35L9.58 16.22L10.7 15.94L9.75 19.57ZM11.57 8.73012L10.06 7.23012L8.65002 8.64012L11.57 11.5601L15.35 7.78012L13.94 6.37012L11.57 8.73012ZM17.84 12.8799C17.84 13.4322 17.3923 13.8799 16.84 13.8799C16.2877 13.8799 15.84 13.4322 15.84 12.8799C15.84 12.3276 16.2877 11.8799 16.84 11.8799C17.3923 11.8799 17.84 12.3276 17.84 12.8799Z",
    fill: "none"
  })
}), 'CertificateIcon', 'Brandbook');
export default CertificateIcon;