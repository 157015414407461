/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VolunteerIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14.9413 3.02488C15.976 3.12871 16.9432 3.58613 17.68 4.32001C18.5245 5.16888 18.9986 6.31759 18.9986 7.51501C18.9986 8.71243 18.5245 9.86115 17.68 10.71L12.68 15.71L11.26 14.29L16.26 9.29001C16.7307 8.81925 16.9952 8.18077 16.9952 7.51501C16.9952 6.84926 16.7307 6.21077 16.26 5.74001C15.7892 5.26925 15.1507 5.00478 14.485 5.00478C13.8192 5.00478 13.1807 5.26925 12.71 5.74001L12 6.44001L11.29 5.74001C10.8192 5.26925 10.1807 5.00478 9.51495 5.00478C8.8492 5.00478 8.21071 5.26925 7.73995 5.74001C7.26919 6.21077 7.00472 6.84926 7.00472 7.51501C7.00472 8.18077 7.26919 8.81925 7.73995 9.29001L6.31995 10.75C5.43829 9.98236 4.88915 8.90235 4.78831 7.73769C4.68746 6.57302 5.04279 5.4147 5.77939 4.50693C6.51598 3.59915 7.57627 3.01286 8.73674 2.87166C9.89721 2.73045 11.0672 3.04537 12 3.75001C12.8678 3.17715 13.9066 2.92105 14.9413 3.02488Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4 9H2V14.41L6 18.41V21H8V17.59L4 13.59V9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20 13.59V9H22V14.41L18 18.41V21H16V17.59L20 13.59Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.14735 11.4764C8.25023 11.3077 8.40078 11.1733 8.58 11.0901C8.69945 11.0346 8.82869 11.0033 8.96028 10.9979C9.09187 10.9925 9.22322 11.0132 9.3468 11.0587C9.47038 11.1043 9.58375 11.1737 9.6804 11.2632C9.77705 11.3527 9.85508 11.4604 9.91 11.5801C9.9924 11.7596 10.0197 11.9596 9.98859 12.1547C9.95744 12.3499 9.86918 12.5314 9.73496 12.6764C9.60073 12.8214 9.42656 12.9234 9.23442 12.9695C9.04229 13.0156 8.8408 13.0037 8.65539 12.9354C8.46999 12.8671 8.30897 12.7454 8.19267 12.5857C8.07637 12.4259 8.01 12.2353 8.00193 12.0379C7.99387 11.8405 8.04447 11.6451 8.14735 11.4764Z",
    fill: "none"
  })]
}), 'VolunteerIcon', 'Brandbook');
export default VolunteerIcon;