/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CustomerSuccessIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.909 5.714c.498.848.76 1.815.758 2.8a5.49 5.49 0 01-3.13 4.96c.2.076.398.162.59.26l-.92 1.78a4.5 4.5 0 00-6.54 4v2.5h-2v-2.5a6.5 6.5 0 014.13-6.05 5.5 5.5 0 117.111-7.75zm-7.652.855a3.5 3.5 0 105.82 3.889 3.5 3.5 0 00-5.82-3.89z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.428 8.702a4 4 0 012.239-.689 4 4 0 010 8 2.12 2.12 0 00-2.33 2.33v.021l-1.999-.388a4.19 4.19 0 011.569-3.073 4 4 0 01.521-6.201zm1.127 4.974a2 2 0 102.223-3.325 2 2 0 00-2.223 3.325z",
    fill: "none"
  }), _jsx("path", {
    d: "M2.333 21a1 1 0 112 0 1 1 0 01-2 0zM23.54 17.707l-1.414-1.414-3.367 3.367-1.801-1.44-1.25 1.56 3.2 2.56 4.632-4.633z",
    fill: "none"
  })]
}), 'CustomerSuccessIcon', 'Brandbook');
export default CustomerSuccessIcon;