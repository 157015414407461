/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ComputerIcon = createIconsComponent(_jsxs("svg", {
  width: 33,
  height: 32,
  viewBox: "0 0 33 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.35 5.45a2.4 2.4 0 0 0-2.402 2.4v11.17H26.85v-2.53a.889.889 0 0 1 1.778 0v3.693a4.179 4.179 0 0 1-4.18 4.178H19.74l.526 3.155h1.715a.889.889 0 1 1 0 1.778H8.818a.889.889 0 1 1 0-1.778h1.715l.526-3.155H6.35a4.179 4.179 0 0 1-4.179-4.178V7.851a4.179 4.179 0 0 1 4.18-4.178h9.426a.889.889 0 0 1 0 1.778H6.35ZM4.028 20.799a2.402 2.402 0 0 0 2.322 1.785h18.098c1.114 0 2.05-.757 2.323-1.785H4.028Zm8.833 3.563-.526 3.155h6.128l-.526-3.155H12.86Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M29.317 3.607a1.75 1.75 0 0 1 .643.043c.21.054.413.148.591.283.18.134.333.308.442.516.11.208.17.442.17.683l-.89-.002h.89v6.031h-.89.89c0 .412-.175.785-.45 1.056a1.67 1.67 0 0 1-.984.463h-.005c-1.368.149-2.645.637-3.677 1.391a1.725 1.725 0 0 1-.988.308 1.725 1.725 0 0 1-.988-.308c-1.032-.754-2.31-1.242-3.678-1.39l-.005-.001a1.671 1.671 0 0 1-.984-.463 1.481 1.481 0 0 1-.45-1.056h.89-.89V5.13c0-.241.06-.475.17-.683.11-.207.263-.38.442-.515s.38-.229.59-.283a1.75 1.75 0 0 1 .644-.042l.012.001c1.552.208 3.02.775 4.247 1.654 1.226-.879 2.694-1.447 4.246-1.654l.012-.002Zm-5.147 3.2a1.643 1.643 0 0 1-.099-.064c-.946-.69-2.097-1.16-3.338-1.35v5.537a9.572 9.572 0 0 1 3.437 1.101V6.808Zm1.778 5.224a9.571 9.571 0 0 1 3.436-1.101V5.393c-1.24.19-2.391.66-3.337 1.35a.815.815 0 0 1-.099.065v5.223ZM8.548 9.189a.889.889 0 0 1 1.257-.001l2.56 2.558a.889.889 0 0 1 0 1.258l-2.56 2.557a.889.889 0 1 1-1.256-1.258l1.93-1.928-1.93-1.93a.889.889 0 0 1-.001-1.256Zm4.42 5.843c0-.491.398-.89.889-.89h3.52a.889.889 0 1 1 0 1.779h-3.52a.889.889 0 0 1-.89-.89Z",
    fill: "none"
  })]
}), 'ComputerIcon', 'Brandbook');
export default ComputerIcon;