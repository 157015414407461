/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ExpertIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.7 7.47L22 8.52L16.3 14.53L17.8 21.5L11 18.64L4.2 21.5L5.7 14.53L0 8.52L4.25 7.91L5.93 9.69L4.11 9.95L7.15 13.15L7.88 13.92L7.65 14.95L6.96 18.17L10.22 16.8L11 16.47L11.78 16.8L15.04 18.17L14.35 14.95L14.13 13.92L14.85 13.15L17.89 9.95L14.41 9.45L13.41 9.31L12.93 8.41L11 4.77L10.15 6.36L9.02 4.23L11 0.5L14.7 7.47ZM8.79001 7.56006C8.79001 8.11234 8.34229 8.56006 7.79001 8.56006C7.23772 8.56006 6.79001 8.11234 6.79001 7.56006C6.79001 7.00777 7.23772 6.56006 7.79001 6.56006C8.34229 6.56006 8.79001 7.00777 8.79001 7.56006Z",
    fill: "none"
  })
}), 'ExpertIcon', 'Brandbook');
export default ExpertIcon;