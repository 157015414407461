/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var Build2Icon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M25.71 9.007c-.457 0-.899-.108-1.293-.313L13.767 19.39l1.476 1.482a.86.86 0 0 1 .254.61.87.87 0 0 1-.254.613l-8.311 8.34a3.756 3.756 0 0 1-2.685 1.115h-.004a3.76 3.76 0 0 1-2.684-1.12 3.801 3.801 0 0 1-1.115-2.696c0-1.015.396-1.979 1.115-2.696l8.31-8.34a.86.86 0 0 1 1.218 0l1.465 1.471L23.202 7.47a2.822 2.822 0 0 1-.31-1.292c0-.757.293-1.47.826-2.001L27.177.697a.86.86 0 0 1 1.217 0l2.77 2.779a.859.859 0 0 1 0 1.218l-3.463 3.479a2.789 2.789 0 0 1-1.991.83v.004Zm-1.102-2.83c0 .293.117.57.323.777l.002.002a1.12 1.12 0 0 0 1.551-.002l2.852-2.865-1.55-1.557-2.855 2.862a1.1 1.1 0 0 0-.323.783ZM2.772 26.262l7.704-7.73 2.939 2.95-7.7 7.731c-.397.393-.917.61-1.472.61a2.055 2.055 0 0 1-1.467-.61 2.077 2.077 0 0 1-.61-1.478c0-.557.214-1.08.606-1.473Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.706 13.618a.855.855 0 0 0 .607.255l.004.008a.869.869 0 0 0 .607-1.478l-2.056-2.065a.855.855 0 0 0-.925-.194 4.027 4.027 0 0 1-1.807.267 3.987 3.987 0 0 1-3.183-2.096c.43.07.864.078 1.299.03a4.778 4.778 0 0 0 2.83-1.373 4.834 4.834 0 0 0 1.364-2.848 4.942 4.942 0 0 0-.034-1.31 3.978 3.978 0 0 1 2.021 2.719c.16.773.095 1.573-.193 2.307a.866.866 0 0 0 .194.925l2.99 3.012a.86.86 0 0 0 1.217 0 .869.869 0 0 0 0-1.223l-2.616-2.632a5.8 5.8 0 0 0-1.471-5.652A5.716 5.716 0 0 0 7.108.637a.857.857 0 0 0-.817.402.86.86 0 0 0 0 .916 3.072 3.072 0 0 1-.43 3.79 3.038 3.038 0 0 1-3.773.432.86.86 0 0 0-1.312.821c.094.877.391 1.737.86 2.48a5.734 5.734 0 0 0 4.354 2.649 5.778 5.778 0 0 0 2.034-.199l1.682 1.69ZM25.796 31.555a5.747 5.747 0 0 1-3.158-.946 5.804 5.804 0 0 1-2.332-3.081 5.812 5.812 0 0 1-.056-3.319l-1.94-1.949a.869.869 0 0 1 0-1.223.86.86 0 0 1 1.218 0l2.319 2.325c.24.242.318.61.193.93a4.08 4.08 0 0 0-.09 2.713c.28.886.86 1.65 1.635 2.16a4.024 4.024 0 0 0 5.063-.526 4.069 4.069 0 0 0 .525-5.087 4.025 4.025 0 0 0-4.853-1.551.848.848 0 0 1-.92-.194l-3.278-3.293a.869.869 0 0 1 0-1.223.86.86 0 0 1 1.217 0l2.904 2.917a5.773 5.773 0 0 1 3.304.056 5.785 5.785 0 0 1 3.979 6.102 5.797 5.797 0 0 1-1.656 3.495 5.75 5.75 0 0 1-4.066 1.694h-.008Z",
    fill: "none"
  }), _jsx("path", {
    d: "M26.794 25.808c0 .597-.482 1.08-1.076 1.08a1.078 1.078 0 0 1-1.075-1.08c0-.596.481-1.08 1.075-1.08s1.076.483 1.076 1.08Z",
    fill: "none"
  })]
}), 'Build2Icon', 'Brandbook');
export default Build2Icon;