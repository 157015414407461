/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var UserNetworkIcon = createIconsComponent(_jsx("svg", {
  width: 33,
  height: 33,
  viewBox: "0 0 33 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M27.854 11.44a.889.889 0 0 0 .407-1.19 13.407 13.407 0 0 0-6.078-6.104.889.889 0 0 0-.79 1.593 11.629 11.629 0 0 1 5.271 5.293.889.889 0 0 0 1.19.407ZM21.161 27.89a.889.889 0 0 0 1.19.407 13.407 13.407 0 0 0 6.103-6.079.889.889 0 1 0-1.593-.789A11.629 11.629 0 0 1 21.57 26.7a.889.889 0 0 0-.408 1.19ZM4.711 21.197a.889.889 0 0 0-.407 1.19 13.407 13.407 0 0 0 6.078 6.103.889.889 0 0 0 .79-1.593A11.629 11.629 0 0 1 5.9 21.604a.889.889 0 0 0-1.189-.407ZM11.403 4.747a.889.889 0 0 0-1.189-.407 13.406 13.406 0 0 0-6.104 6.078.889.889 0 0 0 1.593.79 11.629 11.629 0 0 1 5.293-5.272.889.889 0 0 0 .407-1.19ZM16.284 2.722a1.311 1.311 0 1 0 0 2.623 1.311 1.311 0 0 0 0-2.623Zm-3.09 1.311a3.089 3.089 0 1 1 6.178 0 3.089 3.089 0 0 1-6.177 0ZM30.061 16.5a1.311 1.311 0 1 0-2.622 0 1.311 1.311 0 0 0 2.622 0Zm-1.31-3.089a3.089 3.089 0 1 1 0 6.178 3.089 3.089 0 0 1 0-6.178ZM16.284 27.656a1.311 1.311 0 1 0 0 2.622 1.311 1.311 0 0 0 0-2.622Zm-3.09 1.31a3.089 3.089 0 1 1 6.179 0 3.089 3.089 0 0 1-6.178 0ZM5.128 16.5a1.311 1.311 0 1 0-2.622 0 1.311 1.311 0 0 0 2.622 0Zm-1.311-3.089a3.089 3.089 0 1 1 0 6.178 3.089 3.089 0 0 1 0-6.178ZM14.538 10.339a4.148 4.148 0 0 1 4.454.872c.38.374.684.82.891 1.31a3.988 3.988 0 0 1 0 3.102 4.034 4.034 0 0 1-1.748 1.955c.377.132.742.3 1.089.505a6.03 6.03 0 0 1 2.24 2.315.889.889 0 1 1-1.561.849 4.253 4.253 0 0 0-1.581-1.632 4.379 4.379 0 0 0-2.222-.604c-.783 0-1.551.21-2.221.604-.67.395-1.216.96-1.581 1.632a.889.889 0 0 1-1.562-.849 6.03 6.03 0 0 1 2.24-2.315 6.126 6.126 0 0 1 1.09-.505 4.036 4.036 0 0 1-1.749-1.955 3.99 3.99 0 0 1 .892-4.412c.381-.375.833-.67 1.329-.872ZM16.1 11.81c-.307 0-.61.06-.893.175a2.323 2.323 0 0 0-.753.493c-.214.21-.384.46-.499.733a2.212 2.212 0 0 0 .5 2.453c.214.211.47.38.752.494a2.372 2.372 0 0 0 1.786 0c.283-.115.539-.283.753-.494.215-.21.384-.46.5-.733a2.21 2.21 0 0 0 0-1.72 2.258 2.258 0 0 0-.5-.733 2.322 2.322 0 0 0-.753-.493 2.37 2.37 0 0 0-.893-.175Z",
    fill: "none"
  })
}), 'UserNetworkIcon', 'Brandbook');
export default UserNetworkIcon;