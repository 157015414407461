/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PiechartIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.0526 4.03927C19.3426 5.0207 20.3747 6.30099 21.06 7.76988C22.1816 10.1728 22.3028 12.9228 21.397 15.415C20.4912 17.9073 18.6327 19.9378 16.23 21.0599L15.32 21.4799L10.86 11.9199L15.48 4.79988C15.2372 4.67703 14.9867 4.57013 14.73 4.47988C12.7723 3.76567 10.6132 3.84353 8.71208 4.69691C6.81094 5.55029 5.31781 7.11181 4.55039 9.04925C3.78297 10.9867 3.80184 13.1471 4.60296 15.0709C5.40409 16.9946 6.92425 18.5298 8.84001 19.3499L8.06001 21.1899C6.57008 20.5516 5.25763 19.5607 4.23569 18.3026C3.21375 17.0445 2.51296 15.5568 2.19367 13.9676C1.87439 12.3785 1.94617 10.7356 2.40285 9.18037C2.85952 7.62514 3.6874 6.20424 4.81517 5.04002C5.94295 3.87581 7.33682 3.00319 8.87674 2.49731C10.4167 1.99143 12.0565 1.86746 13.655 2.13608C15.2534 2.40469 16.7626 3.05784 18.0526 4.03927ZM19.25 8.61988C18.7607 7.56556 18.0475 6.63054 17.16 5.87988L13.84 10.9999H19.94C19.8334 10.1759 19.6007 9.37313 19.25 8.61988ZM13.57 12.9999L16.26 18.7699C17.7655 17.823 18.9126 16.4015 19.52 14.7299C19.7247 14.1715 19.8623 13.5907 19.93 12.9999H13.57Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.1473 20.4761C11.2502 20.3074 11.4008 20.173 11.58 20.0898C11.6995 20.0344 11.8287 20.003 11.9603 19.9977C12.0919 19.9923 12.2232 20.013 12.3468 20.0585C12.4704 20.104 12.5838 20.1735 12.6804 20.263C12.7771 20.3524 12.8551 20.4601 12.91 20.5798C12.9924 20.7594 13.0197 20.9594 12.9886 21.1545C12.9574 21.3496 12.8692 21.5311 12.735 21.6761C12.6007 21.8211 12.4266 21.9231 12.2344 21.9692C12.0423 22.0153 11.8408 22.0035 11.6554 21.9352C11.47 21.8669 11.309 21.7452 11.1927 21.5854C11.0764 21.4257 11.01 21.2351 11.0019 21.0376C10.9939 20.8402 11.0445 20.6448 11.1473 20.4761Z",
    fill: "none"
  })]
}), 'PiechartIcon', 'Brandbook');
export default PiechartIcon;