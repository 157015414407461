/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UsabilityIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12.8527 4.52361C12.7498 4.69231 12.5992 4.82674 12.42 4.90994C12.3005 4.9654 12.1713 4.99672 12.0397 5.0021C11.9081 5.00747 11.7768 4.9868 11.6532 4.94127C11.5296 4.89574 11.4162 4.82625 11.3196 4.73679C11.2229 4.64732 11.1449 4.53965 11.09 4.41994C11.0076 4.24035 10.9803 4.04038 11.0114 3.84526C11.0426 3.65014 11.1308 3.46863 11.265 3.32363C11.3993 3.17862 11.5734 3.07663 11.7656 3.03053C11.9577 2.98443 12.1592 2.99628 12.3446 3.06459C12.53 3.13289 12.691 3.2546 12.8073 3.41433C12.9236 3.57407 12.99 3.76468 12.9981 3.96211C13.0061 4.15953 12.9555 4.35492 12.8527 4.52361Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.88 6.50984C20.9 6.68984 21.28 10.8698 18.83 13.3298C17.1903 14.7549 15.1581 15.6507 13 15.8998V19.9998H18V21.9998H6V19.9998H11V15.8898C8.84389 15.6406 6.81258 14.7486 5.17 13.3298C2.86481 11.0152 3.08267 7.17795 3.11648 6.5825C3.1186 6.54509 3.12 6.52048 3.12 6.50984L3.2 5.69984L4 5.61984C4.19 5.59984 8.37 5.21984 10.84 7.69984C12.56 9.42984 12.94 12.2998 13.01 13.9098C14.6425 13.6837 16.1744 12.989 17.42 11.9098C18.4268 10.6991 18.9598 9.16399 18.92 7.58984C17.3458 7.55006 15.8108 8.08307 14.6 9.08984L13.18 7.66984C15.4946 5.36465 19.3319 5.58251 19.9273 5.61632C19.9647 5.61844 19.9894 5.61984 20 5.61984L20.8 5.69984L20.88 6.50984ZM5.09 7.58984C5.05022 9.16399 5.58324 10.6991 6.59 11.9098C7.84095 12.9719 9.37259 13.6492 11 13.8598C10.92 12.4098 10.56 10.2298 9.41 9.08984C8.19521 8.09022 6.66288 7.55816 5.09 7.58984Z",
    fill: "none"
  })]
}), 'UsabilityIcon', 'Brandbook');
export default UsabilityIcon;