/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ReportIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.333 0h-5v19h16V0h-5v2h3v15h-12V2h3V0z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.333 2a1 1 0 110-2 1 1 0 010 2zM5.333 3h-3v21h16v-4h-2v2h-12V5h1V3zM10.333 5h6v2h-6V5z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.333 9h8v2h-8V9zM10.333 13h8v2h-8v-2z",
    fill: "none"
  })]
}), 'ReportIcon', 'Brandbook');
export default ReportIcon;