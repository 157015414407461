/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityDeforestationIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.25 6.75C11.8023 6.75 12.25 6.30228 12.25 5.75C12.25 5.19772 11.8023 4.75 11.25 4.75C10.6977 4.75 10.25 5.19772 10.25 5.75C10.25 6.30228 10.6977 6.75 11.25 6.75Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.97749 4.08665C6.75369 3.45422 7.69321 3 8.75 3V5C8.28799 5 7.78061 5.19733 7.24079 5.63715C6.7 6.07777 6.17884 6.72193 5.72162 7.49498C4.79681 9.05859 4.25 10.947 4.25 12.25C4.25 13.4435 4.72411 14.5881 5.56802 15.432C6.41193 16.2759 7.55653 16.75 8.75 16.75H12.4935V18.75H8.75C7.02609 18.75 5.37279 18.0652 4.15381 16.8462C2.93482 15.6272 2.25 13.9739 2.25 12.25C2.25 10.5154 2.93439 8.27881 4.00018 6.47682C4.53826 5.56707 5.20225 4.71828 5.97749 4.08665Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.25 21.75V17.75H9.25V21.75H7.25Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.1973 4.22021L21.8017 18.7499H16.2487V21.7499H14.2487V18.7499H9.29297L15.1973 4.22021ZM18.6957 16.7499L15.3002 9.27968L12.2645 16.7499H18.6957Z",
    fill: "none"
  })]
}), 'SustainabilityDeforestationIcon', 'Brandbook');
export default SustainabilityDeforestationIcon;