/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityNeutralizingIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M16.6665 16C17.2188 16 17.6665 15.5523 17.6665 15C17.6665 14.4477 17.2188 14 16.6665 14C16.1142 14 15.6665 14.4477 15.6665 15C15.6665 15.5523 16.1142 16 16.6665 16Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.16748 5C5.76966 5 5.38812 5.15804 5.10682 5.43934C4.82552 5.72064 4.66748 6.10218 4.66748 6.5V8.5C4.66748 8.89782 4.82552 9.27936 5.10682 9.56066C5.38812 9.84196 5.76966 10 6.16748 10C6.56531 10 6.94684 9.84196 7.22814 9.56066C7.50945 9.27936 7.66748 8.89782 7.66748 8.5H9.66748C9.66748 9.42826 9.29873 10.3185 8.64235 10.9749C7.98598 11.6313 7.09574 12 6.16748 12C5.23922 12 4.34898 11.6313 3.69261 10.9749C3.03623 10.3185 2.66748 9.42826 2.66748 8.5V6.5C2.66748 5.57174 3.03623 4.6815 3.69261 4.02513C4.34898 3.36875 5.23922 3 6.16748 3C7.09574 3 7.98598 3.36875 8.64235 4.02513C9.29873 4.6815 9.66748 5.57174 9.66748 6.5H7.66748C7.66748 6.10218 7.50945 5.72064 7.22814 5.43934C6.94684 5.15804 6.56531 5 6.16748 5Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.1685 5C13.34 5 12.6685 5.67157 12.6685 6.5V8.5C12.6685 9.32843 13.34 10 14.1685 10C14.9969 10 15.6685 9.32843 15.6685 8.5V6.5C15.6685 5.67157 14.9969 5 14.1685 5ZM10.6685 6.5C10.6685 4.567 12.2355 3 14.1685 3C16.1015 3 17.6685 4.567 17.6685 6.5V8.5C17.6685 10.433 16.1015 12 14.1685 12C12.2355 12 10.6685 10.433 10.6685 8.5V6.5Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.2289 9C21.0798 9.00004 20.9368 9.05931 20.8313 9.16478C20.7258 9.27026 20.6665 9.41332 20.6665 9.5625C20.6665 9.56252 20.6665 9.56248 20.6665 9.5625V11H18.6665V9.5625C18.6665 8.88291 18.9365 8.23111 19.4171 7.75057C19.8976 7.27003 20.5494 7.00004 21.2289 7C21.9085 7.00004 22.5604 7.27003 23.0409 7.75057C23.5215 8.23111 23.7915 8.88285 23.7915 9.56244V11.9107L21.2436 14H23.6665V16H18.6665V13.5268L21.7915 10.9643V9.56256C21.7915 9.56254 21.7915 9.56259 21.7915 9.56256C21.7915 9.41339 21.7322 9.27026 21.6267 9.16478C21.5213 9.05931 21.3781 9.00004 21.2289 9Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M3.9855 17.682C3.14159 16.8381 2.66748 15.6935 2.66748 14.5H4.66748C4.66748 15.163 4.93087 15.7989 5.39971 16.2678C5.86781 16.7359 6.50241 16.9992 7.16431 17C7.54981 16.9971 7.92926 16.9037 8.27209 16.7273L9.50624 16.0923L9.71789 17.464C9.83294 18.2097 10.2254 18.8844 10.8168 19.3529C11.4081 19.8215 12.1547 20.0494 12.9069 19.991C13.6591 19.9326 14.3615 19.5922 14.8734 19.0379C15.3853 18.4836 15.6689 17.7564 15.6675 17.0019L17.6675 16.9981C17.6699 18.2569 17.1967 19.4701 16.3427 20.3948C15.4886 21.3196 14.3168 21.8875 13.0618 21.985C11.8067 22.0825 10.5612 21.7023 9.57466 20.9205C8.89985 20.3857 8.38025 19.6902 8.05781 18.9058C7.76797 18.9664 7.47187 18.9981 7.17379 19L7.16748 19V19C5.97401 19 4.82941 18.5259 3.9855 17.682Z",
    fill: "none"
  })]
}), 'SustainabilityNeutralizingIcon', 'Brandbook');
export default SustainabilityNeutralizingIcon;