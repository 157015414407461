/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PerformanceMonitoringIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M22 12C21.999 14.3976 21.1366 16.715 19.57 18.53C20.0083 18.9186 20.4131 19.3434 20.78 19.8L19.22 21.05C18.3534 19.9679 17.2545 19.0944 16.0048 18.4941C14.7551 17.8939 13.3864 17.5822 12 17.5822C10.6136 17.5822 9.24493 17.8939 7.9952 18.4941C6.74547 19.0944 5.64665 19.9679 4.78 21.05L3.22 19.8C3.58692 19.3434 3.99169 18.9186 4.43 18.53C2.91123 16.7717 2.0521 14.5395 2.00018 12.2167C1.94826 9.89383 2.70678 7.62542 4.14547 5.80103C5.58415 3.97664 7.61321 2.71015 9.88413 2.21909C12.155 1.72802 14.5261 2.04303 16.59 3.11002L15.67 4.89001C14.8364 4.4568 13.9323 4.17573 13 4.06001V6.00002H11V4.07002C9.5776 4.24806 8.22993 4.80786 7.1 5.69002L8.71 7.29002L7.29 8.71002L5.69 7.10002C4.80785 8.22994 4.24805 9.57761 4.07 11H6V13H4.07C4.26693 14.5946 4.93946 16.093 6 17.3C7.79797 16.172 9.87749 15.5737 12 15.5737C14.1225 15.5737 16.202 16.172 18 17.3C19.0606 16.093 19.7331 14.5946 19.93 13H18V11H19.93C19.8164 10.0686 19.5388 9.16465 19.11 8.33002L20.89 7.41002C21.1005 7.81627 21.281 8.23739 21.43 8.67002C21.809 9.73926 22.0018 10.8656 22 12Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7Z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.2204 7.3772L11.2241 12.3761L12.7863 13.625L16.7826 8.62605L15.2204 7.3772Z",
    fill: "none"
  })]
}), 'PerformanceMonitoringIcon', 'Brandbook');
export default PerformanceMonitoringIcon;