/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ToolIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M15 2.34009L16.5 3.21009C17.8233 3.97548 18.9299 5.06533 19.7153 6.37686C20.5007 7.6884 20.9391 9.17838 20.9891 10.7063C21.0392 12.2342 20.6992 13.7497 20.0012 15.1098C19.3033 16.4699 18.2704 17.6298 17 18.4801V23.0001H11V21.0001H15V17.3901L15.5 17.1101C16.4314 16.5773 17.2279 15.8376 17.8278 14.948C18.4278 14.0584 18.8153 13.0428 18.9602 11.9797C19.1052 10.9165 19.0038 9.83424 18.664 8.8165C18.3241 7.79876 17.7548 6.87277 17 6.11009V9.00009C17 10.3262 16.4732 11.5979 15.5355 12.5356C14.5979 13.4733 13.3261 14.0001 12 14.0001C10.6739 14.0001 9.40216 13.4733 8.46447 12.5356C7.52679 11.5979 7.00001 10.3262 7.00001 9.00009V6.11009C6.25494 6.87269 5.69391 7.79542 5.35973 8.80785C5.02555 9.82028 4.92705 10.8957 5.07175 11.952C5.21645 13.0083 5.60052 14.0176 6.19466 14.9028C6.78879 15.7881 7.5773 16.5259 8.50001 17.0601L7.50001 18.7901C6.13382 17.9996 4.99954 16.8637 4.21098 15.4964C3.42243 14.1291 3.00732 12.5785 3.00732 11.0001C3.00732 9.42169 3.42243 7.87105 4.21098 6.50375C4.99954 5.13645 6.13382 4.00057 7.50001 3.21009L9.00001 2.34009V9.00009C9.00001 9.79574 9.31608 10.5588 9.87869 11.1214C10.4413 11.684 11.2044 12.0001 12 12.0001C12.7957 12.0001 13.5587 11.684 14.1213 11.1214C14.6839 10.5588 15 9.79574 15 9.00009V2.34009Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8 23C8.55228 23 9 22.5523 9 22C9 21.4477 8.55228 21 8 21C7.44772 21 7 21.4477 7 22C7 22.5523 7.44772 23 8 23Z",
    fill: "none"
  })]
}), 'ToolIcon', 'Brandbook');
export default ToolIcon;