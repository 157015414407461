/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MonitorIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M2 3H22V17H13V19H17V21H7V19H11V17H2V13H4V15H20V5H4V7H2V3Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4 10C4 10.5523 3.55228 11 3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9C3.55228 9 4 9.44772 4 10Z",
    fill: "none"
  })]
}), 'MonitorIcon', 'Brandbook');
export default MonitorIcon;