/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var CatalogIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.4 2h-1v4.25H2V21h2V8.25h4.114l1.306 2.082.294.468H17.3V19H8v2h11.3v-5.1h3.4V2H6.4Zm12.9 11.9h1.4V4H7.4v2.25H9.22l.294.469L10.82 8.8h8.48v5.1ZM6 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z",
    fill: "none"
  })
}), 'CatalogIcon', 'Brandbook');
export default CatalogIcon;