/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ImportIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.7101 9.71L17.2901 8.29L13.0001 12.59V3H11.0001V12.59L6.71004 8.29L5.29004 9.71L12.0001 16.41L18.7101 9.71ZM2 14H4V19H9V21H2V14ZM20 19V14H22V21H15V19H20ZM13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z",
    fill: "none"
  })
}), 'ImportIcon', 'Brandbook');
export default ImportIcon;