/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityRefrigerantIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M19.7498 10.4583C20.2791 10.4583 20.7082 10.0292 20.7082 9.49996C20.7082 8.97069 20.2791 8.54163 19.7498 8.54163C19.2206 8.54163 18.7915 8.97069 18.7915 9.49996C18.7915 10.0292 19.2206 10.4583 19.7498 10.4583Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.0825 21V3.75H13.9992V21H12.0825Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.4165 11.4167H21.6665V13.3334H4.4165V11.4167Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.0427 7.43337L8.86237 3.9704L7.63965 5.4464L13.0427 9.92226L18.4457 5.4464L17.223 3.9704L13.0427 7.43337Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.0427 14.9639L18.4457 19.4397L17.223 20.9157L13.0427 17.4528L8.86237 20.9157L7.63965 19.4397L13.0427 14.9639Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.5406 11.8263L21.51 16.5501L20.0426 17.7831L16.0732 13.0593L17.5406 11.8263Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M8.03918 12.443L4.56494 8.18953L6.04936 6.97705L10.514 12.443L6.04936 17.9089L4.56494 16.6965L8.03918 12.443Z",
    fill: "none"
  })]
}), 'SustainabilityRefrigerantIcon', 'Brandbook');
export default SustainabilityRefrigerantIcon;