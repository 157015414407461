/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var BuildIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  fill: "none",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.35 17.735 12.727 5.72l1.925.541-3.375 12.015-1.925-.54ZM14.87 7.5 16.19 6 23 12l-6.81 6-1.32-1.5 5.11-4.5-5.11-4.5ZM6.89 9.47l-1.32-1.5L1 12l6.81 6 1.32-1.5L4.02 12l2.87-2.53ZM9.19 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
  })
}), 'BuildIcon', 'Brandbook');
export default BuildIcon;