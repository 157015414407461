/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RetainingIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5 6.99992H12V10.5799L21.14 5.74992L12 0.919922V4.99992H3V10.9999H5V6.99992ZM14 4.23992L16.86 5.74992L14 7.25992V4.23992Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.71 14.7098C4.84903 14.5696 4.94346 14.3914 4.98138 14.1977C5.0193 14.0039 4.99902 13.8033 4.92309 13.621C4.84717 13.4388 4.71899 13.2831 4.55472 13.1736C4.39045 13.0641 4.19743 13.0056 4 13.0056C3.80257 13.0056 3.60955 13.0641 3.44528 13.1736C3.28101 13.2831 3.15284 13.4388 3.07691 13.621C3.00098 13.8033 2.9807 14.0039 3.01862 14.1977C3.05654 14.3914 3.15097 14.5696 3.29 14.7098C3.38296 14.8035 3.49356 14.8779 3.61542 14.9287C3.73728 14.9795 3.86799 15.0056 4 15.0056C4.13201 15.0056 4.26272 14.9795 4.38458 14.9287C4.50644 14.8779 4.61704 14.8035 4.71 14.7098Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11 17H18V9H20V19H11V22.66L1.85999 17.83L11 13V17ZM6.13999 17.83L8.99999 19.34V16.32L6.13999 17.83Z",
    fill: "none"
  })]
}), 'RetainingIcon', 'Brandbook');
export default RetainingIcon;