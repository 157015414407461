/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MessageIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20 5H15V3H22V17H8.28L2 20.77V3H9V5H4V17.23L7.72 15H20V5Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13 4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7 7V9H17V7H7Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7 13V11H17V13H7Z",
    fill: "none"
  })]
}), 'MessageIcon', 'Brandbook');
export default MessageIcon;