/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AnalyticsIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M22 7.94V17.94L15.89 18L18.67 20.18L17.43 21.75L12.68 18H11.39L6.68 21.81L5.42 20.26L8.23 18H2.09L2 4H11V2H13V4H18V6L4 6.05V14.48L8.82 8.67L12.91 11.67L17.27 7.26L18.69 8.63L13.11 14.27L9.16 11.35L5.33 16L20 15.94V7.94H22ZM21.7071 5.70711C21.5196 5.89464 21.2652 6 21 6C20.8022 6 20.6089 5.94135 20.4444 5.83147C20.28 5.72159 20.1518 5.56541 20.0761 5.38268C20.0004 5.19996 19.9806 4.99889 20.0192 4.80491C20.0578 4.61093 20.153 4.43275 20.2929 4.29289C20.4327 4.15304 20.6109 4.0578 20.8049 4.01922C20.9989 3.98063 21.2 4.00043 21.3827 4.07612C21.5654 4.15181 21.7216 4.27998 21.8315 4.44443C21.9414 4.60888 22 4.80222 22 5C22 5.26522 21.8946 5.51957 21.7071 5.70711Z",
    fill: "none"
  })
}), 'AnalyticsIcon', 'Brandbook');
export default AnalyticsIcon;