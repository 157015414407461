/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ComprehensiveHealthIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M19 9H20V19H4V9H13V7H9V4H15V5H17V2H7V7H2V21H22V7H19V9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13 15V18H11V15H8V13H11V10H13V13H16V15H13Z",
    fill: "none"
  })]
}), 'ComprehensiveHealthIcon', 'Brandbook');
export default ComprehensiveHealthIcon;