/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AnnouncementIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14 5.22L2 9.28V14.72L5 15.72V22H9.68L11.33 17.88L14 18.78V21H16V15H14V16.67L10 15.32V8.68L14 7.33V9H16V3H14V5.22ZM8.32 20H7V16.41L9.43 17.23L8.32 20ZM8 14.64L4 13.28V10.72L8 9.36V14.64ZM14.58 11.0901C14.4008 11.1733 14.2502 11.3077 14.1473 11.4764C14.0445 11.6451 13.9939 11.8405 14.0019 12.0379C14.01 12.2353 14.0764 12.4259 14.1927 12.5857C14.309 12.7454 14.47 12.8671 14.6554 12.9354C14.8408 13.0037 15.0423 13.0156 15.2344 12.9695C15.4266 12.9234 15.6007 12.8214 15.735 12.6764C15.8692 12.5314 15.9574 12.3499 15.9886 12.1547C16.0197 11.9596 15.9924 11.7596 15.91 11.5801C15.8551 11.4604 15.7771 11.3527 15.6804 11.2632C15.5838 11.1737 15.4704 11.1043 15.3468 11.0587C15.2232 11.0132 15.0919 10.9925 14.9603 10.9979C14.8287 11.0033 14.6995 11.0346 14.58 11.0901ZM18 11H23V13H18V11ZM20.2967 4.2915L17.2986 7.28963L18.7128 8.70384L21.7109 5.70571L20.2967 4.2915ZM17.2968 16.7038L18.711 15.2896L21.7091 18.2878L20.2949 19.702L17.2968 16.7038Z",
    fill: "none"
  })
}), 'AnnouncementIcon', 'Brandbook');
export default AnnouncementIcon;