/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PlugIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.3209 3.32094C15.1643 2.4776 16.3073 2.00264 17.5 2C18.6927 2.00264 19.8357 2.4776 20.6791 3.32094C21.5224 4.16428 21.9974 5.30734 22 6.5V11H20V6.5C20 5.83696 19.7366 5.20107 19.2678 4.73223C18.7989 4.26339 18.163 4 17.5 4C16.837 4 16.2011 4.26339 15.7322 4.73223C15.2634 5.20107 15 5.83696 15 6.5V17.5C15 18.6935 14.5259 19.8381 13.682 20.682C12.8381 21.5259 11.6935 22 10.5 22C9.30653 22 8.16193 21.5259 7.31802 20.682C6.47411 19.8381 6 18.6935 6 17.5V14.86C5.14352 14.6389 4.38451 14.1401 3.84169 13.4416C3.29887 12.7432 3.00287 11.8846 3 11V8H2V6H4V2H6V6H8V2H10V6H12V8H11V11C10.9971 11.8846 10.7011 12.7432 10.1583 13.4416C9.61549 14.1401 8.85648 14.6389 8 14.86V17.5C8 18.163 8.26339 18.7989 8.73223 19.2678C9.20107 19.7366 9.83696 20 10.5 20C11.163 20 11.7989 19.7366 12.2678 19.2678C12.7366 18.7989 13 18.163 13 17.5V6.5C13.0026 5.30734 13.4776 4.16428 14.3209 3.32094ZM5 8V11C5 11.5304 5.21071 12.0391 5.58579 12.4142C5.96086 12.7893 6.46957 13 7 13C7.53043 13 8.03914 12.7893 8.41421 12.4142C8.78929 12.0391 9 11.5304 9 11V8H5Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21 15C21.5523 15 22 14.5523 22 14C22 13.4477 21.5523 13 21 13C20.4477 13 20 13.4477 20 14C20 14.5523 20.4477 15 21 15Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20 17V21H22V17H20Z",
    fill: "none"
  })]
}), 'PlugIcon', 'Brandbook');
export default PlugIcon;