/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityNitrousoxideIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.73267 18.2988C9.22968 18.2988 9.63258 17.8959 9.63258 17.3989C9.63258 16.9019 9.22968 16.499 8.73267 16.499C8.23567 16.499 7.83276 16.9019 7.83276 17.3989C7.83276 17.8959 8.23567 18.2988 8.73267 18.2988Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.375 6.11322L14.889 7.08648L11.7307 11.9993H16.1207L11.3167 20.5397L9.74803 19.6573L13.0432 13.7991H8.43408L13.375 6.11322Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.29632 4.38373C10.5137 3.40731 12.0506 2.91814 13.6084 3.0112C15.1662 3.10426 16.6339 3.77291 17.7264 4.88732C18.7146 5.89522 19.332 7.20269 19.487 8.59704C20.1917 9.16427 20.7298 9.91597 21.0388 10.7724C21.3995 11.7725 21.4285 12.8622 21.1215 13.88C20.8145 14.8979 20.1878 15.7898 19.3342 16.4236C18.4806 17.0574 17.4456 17.3994 16.3824 17.3989L16.3832 15.5991C17.0596 15.5994 17.7182 15.3818 18.2612 14.9786C18.8043 14.5753 19.203 14.0079 19.3984 13.3603C19.5937 12.7127 19.5753 12.0194 19.3457 11.3831C19.1162 10.7468 18.6878 10.2014 18.1241 9.82761L17.7405 9.57329L17.7222 9.11342C17.6778 8.00008 17.2213 6.94295 16.4412 6.1473C15.6612 5.35165 14.6133 4.87426 13.5011 4.80782C12.3888 4.74138 11.2916 5.09063 10.4224 5.78776C9.55319 6.48489 8.97407 7.48014 8.79746 8.58029L8.6865 9.27152L7.98922 9.33397C7.34358 9.3918 6.73173 9.64829 6.23785 10.0681C5.74397 10.488 5.39234 11.0506 5.23134 11.6785C5.07033 12.3064 5.10787 12.9688 5.33879 13.5745C5.5697 14.1802 5.98265 14.6994 6.5208 15.0608L5.51745 16.555C4.67044 15.9862 4.02049 15.169 3.65704 14.2156C3.2936 13.2623 3.23451 12.2198 3.48792 11.2315C3.74133 10.2432 4.29477 9.3577 5.07211 8.69687C5.67643 8.18312 6.39299 7.82479 7.15951 7.64796C7.50643 6.36648 8.25185 5.22143 9.29632 4.38373Z",
    fill: "none"
  })]
}), 'SustainabilityNitrousoxideIcon', 'Brandbook');
export default SustainabilityNitrousoxideIcon;