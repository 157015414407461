/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EmailIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2 20V4H22V16H20V9.7L12 14.14L4 9.7V18H18V20H2ZM4 7.41L12 11.86L20 7.41V6H4V7.41Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22 19C22 19.5523 21.5523 20 21 20C20.4477 20 20 19.5523 20 19C20 18.4477 20.4477 18 21 18C21.5523 18 22 18.4477 22 19Z",
    fill: "none"
  })]
}), 'EmailIcon', 'Brandbook');
export default EmailIcon;