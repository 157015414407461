/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BusinessIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M7.412 1.979v5.1h-5.1v14.342h20.442V7.08h-3.12v2.042h1.078V19.38H4.354V9.121h9.18V7.08h-4.08V4.021h6.158V5.1h2.042V1.979H7.412z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.633 8.1c0 .6-.5 1-1 1s-1-.5-1-1c0-.6.5-1 1-1s1 .5 1 1z",
    fill: "none"
  })]
}), 'BusinessIcon', 'Brandbook');
export default BusinessIcon;