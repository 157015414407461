/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FamilyIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.7 5.83008C20.3441 6.77938 20.7615 7.86393 20.92 9.00008H21V11.0001H4.1C4.33053 12.1294 4.94418 13.1445 5.8371 13.8734C6.73001 14.6023 7.84735 15.0003 9 15.0001H14C15.042 14.9933 16.0559 14.6612 16.9 14.0501L18.08 15.6701C16.8928 16.5296 15.4657 16.9948 14 17.0001L19.35 20.1401L18.35 21.8601L12.06 18.1601L5.76 21.8601L4.76 20.1401L10.09 17.0001H9C7.14348 17.0001 5.36301 16.2626 4.05025 14.9498C2.7375 13.6371 2 11.8566 2 10.0001V9.00008H11.07V2.00008H12.07C13.6513 1.9937 15.21 2.37481 16.61 3.11008C17.8437 3.7552 18.9036 4.68822 19.7 5.83008ZM15.67 4.88008C14.8611 4.44936 13.9797 4.17139 13.07 4.06008V9.00008H18.85C18.7041 8.28303 18.415 7.60279 18 7.00008C17.4071 6.11465 16.6073 5.38701 15.67 4.88008Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20 13C20 13.5523 19.5523 14 19 14C18.4477 14 18 13.5523 18 13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13Z",
    fill: "none"
  })]
}), 'FamilyIcon', 'Brandbook');
export default FamilyIcon;