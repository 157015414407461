/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FlexiblePolicyIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.9877 8.57507C19.0578 9.75479 18.8281 10.9331 18.32 12.0001H22V14.0001H2V12.0001H5.68C5.17189 10.9331 4.9422 9.75479 5.01234 8.57507C5.08247 7.39535 5.45014 6.25259 6.08109 5.25331C6.71203 4.25402 7.5857 3.43075 8.62067 2.86022C9.65563 2.28969 10.8182 1.99048 12 1.99048C13.1818 1.99048 14.3444 2.28969 15.3793 2.86022C16.4143 3.43075 17.288 4.25402 17.9189 5.25331C18.5499 6.25259 18.9175 7.39535 18.9877 8.57507ZM8.46447 5.46453C7.52678 6.40221 7 7.67398 7 9.00006C6.99487 10.0827 7.34629 11.137 8 12.0001H16C16.6537 11.137 17.0051 10.0827 17 9.00006C17 7.67398 16.4732 6.40221 15.5355 5.46453C14.5979 4.52685 13.3261 4.00006 12 4.00006C10.6739 4.00006 9.40215 4.52685 8.46447 5.46453Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6 18C6.55228 18 7 17.5523 7 17C7 16.4477 6.55228 16 6 16C5.44772 16 5 16.4477 5 17C5 17.5523 5.44772 18 6 18Z",
    fill: "none"
  }), _jsx("path", {
    d: "M16 22C16.5523 22 17 21.5523 17 21C17 20.4477 16.5523 20 16 20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16 22Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20 16H10V18H20V16Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7 22V20H13V22H7Z",
    fill: "none"
  })]
}), 'FlexiblePolicyIcon', 'Brandbook');
export default FlexiblePolicyIcon;