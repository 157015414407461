/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AnnouncementIcon2 = createIconsComponent(_jsx("svg", {
  width: 33,
  height: 33,
  viewBox: "0 0 33 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "m17.814 3.09-2.906 2.346a.81.81 0 0 1-.55.179l-3.727-.19a.37.37 0 0 0-.374.272L9.28 9.3a.81.81 0 0 1-.338.467l-3.125 2.04a.372.372 0 0 0-.143.44l1.33 3.488a.81.81 0 0 1 0 .578l-.57 1.493a.811.811 0 1 1-1.515-.577l.46-1.205-1.22-3.2a1.99 1.99 0 0 1 .772-2.374L7.798 8.58l.894-3.305a1.99 1.99 0 0 1 2.021-1.468l3.418.174 2.665-2.15a1.99 1.99 0 0 1 2.498 0l2.667 2.15 3.418-.17A1.99 1.99 0 0 1 27.4 5.278l.893 3.306 2.866 1.871a1.99 1.99 0 0 1 .773 2.374l-1.219 3.2 1.219 3.199a1.993 1.993 0 0 1-.773 2.375l-2.866 1.866-.899 3.304a1.988 1.988 0 0 1-2.02 1.47l-3.419-.17-2.666 2.146a.81.81 0 0 1-1.016-1.263l2.906-2.34a.81.81 0 0 1 .548-.178l3.728.185a.37.37 0 0 0 .374-.271v-.002l.98-3.604a.81.81 0 0 1 .341-.468l3.124-2.032a.372.372 0 0 0 .143-.442l-1.328-3.486a.81.81 0 0 1 0-.578l1.328-3.488a.37.37 0 0 0-.143-.44l-3.125-2.04a.81.81 0 0 1-.34-.468l-.975-3.602a.368.368 0 0 0-.374-.271l-3.728.185a.81.81 0 0 1-.548-.179l-2.906-2.345a.37.37 0 0 0-.464 0Zm-3.956 7.828a.81.81 0 0 1 1.117.25l7.58 11.942a.637.637 0 0 1 .013.02l.408.643a.81.81 0 0 1-1.368.869l-.088-.138-6.848 2.142a3.677 3.677 0 0 1-1.268 4.348l-.01.01a3.406 3.406 0 0 1-4.842-1.015l-.772-1.186-2.238.7a.81.81 0 0 1-1.472.625l-.408-.644a.602.602 0 0 1-.013-.019l-1.263-1.99a.597.597 0 0 1-.012-.02l-.41-.644a.81.81 0 0 1 1.13-1.112L13.72 12.215l-.113-.178a.81.81 0 0 1 .25-1.119Zm.782 2.748L4.062 27.09l.623.982 3.165-.99a.834.834 0 0 1 .064-.02l5.277-1.652a.777.777 0 0 1 .074-.022l7.355-2.301-5.98-9.42Zm-1.522 13.465-3.731 1.167.53.816a1.785 1.785 0 0 0 2.548.56 2.058 2.058 0 0 0 .653-2.543Z",
    fill: "none"
  })
}), 'AnnouncementIcon2', 'Brandbook');
export default AnnouncementIcon2;