/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UserTarget = createIconsComponent(_jsxs("svg", {
  width: 33,
  height: 32,
  viewBox: "0 0 33 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M2.22656 6.93403C2.58088 7.28835 2.99569 7.56489 3.46236 7.75934C3.9247 7.94946 4.41729 8.04884 4.91853 8.04884C5.41976 8.04884 5.90803 7.94946 6.3747 7.75934C6.75494 7.60378 7.10062 7.38341 7.40741 7.11551L9.30865 8.4939C9.45988 8.60625 9.64136 8.6581 9.8142 8.6581C10.0821 8.6581 10.3457 8.53279 10.5142 8.29946C10.7951 7.91489 10.7086 7.37477 10.3198 7.09391L8.41852 5.71551C8.41852 5.71551 8.42284 5.70255 8.42716 5.69823C8.61729 5.23588 8.71667 4.74329 8.71667 4.24206C8.71667 3.74082 8.61729 3.25255 8.42716 2.78589C8.23704 2.32354 7.95618 1.90873 7.60186 1.55441C7.24754 1.20009 6.83272 0.923544 6.36606 0.729099C5.43705 0.344532 4.38273 0.344532 3.45804 0.729099C2.99569 0.919223 2.58088 1.20009 2.22224 1.55441C1.86791 1.90873 1.59137 2.32354 1.39693 2.78589C1.2068 3.24823 1.10742 3.74082 1.10742 4.24206C1.10742 4.74329 1.2068 5.23156 1.39693 5.69823C1.58705 6.16057 1.86791 6.57971 2.22224 6.93403H2.22656ZM3.00001 3.45132C3.10372 3.2007 3.25495 2.97169 3.44939 2.77725C3.64384 2.5828 3.86853 2.43157 4.12347 2.32786C4.3784 2.22416 4.64199 2.16799 4.91853 2.16799C5.19507 2.16799 5.45865 2.21984 5.71359 2.32786C5.96421 2.43157 6.19322 2.5828 6.38766 2.77725C6.58211 2.97169 6.73334 3.19638 6.83704 3.45132C6.94075 3.70626 6.99692 3.96984 6.99692 4.24638C6.99692 4.52292 6.94507 4.7865 6.83704 5.04144C6.73334 5.29638 6.58211 5.52107 6.38766 5.71551C6.19322 5.90996 5.96853 6.06119 5.71359 6.16489C5.20371 6.37662 4.63334 6.37662 4.12347 6.16489C3.87285 6.06119 3.64384 5.90996 3.44939 5.71551C3.25495 5.52107 3.10372 5.29638 3.00001 5.04144C2.89631 4.79082 2.84014 4.52292 2.84014 4.24638C2.84014 3.96984 2.89199 3.70626 3.00001 3.45132Z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.30432 23.5007L7.40309 24.8791C7.0963 24.6112 6.75062 24.3951 6.37038 24.2396C5.44137 23.855 4.38705 23.855 3.46236 24.2396C3.00001 24.4297 2.5852 24.7062 2.22656 25.0649C1.87224 25.4192 1.59569 25.834 1.40125 26.2963C1.21113 26.7587 1.11174 27.2513 1.11174 27.7525C1.11174 28.2538 1.21113 28.742 1.40125 29.2087C1.59137 29.671 1.87224 30.0859 2.22656 30.4445C2.58088 30.7988 2.99569 31.0754 3.45804 31.2698C3.92038 31.4599 4.41297 31.5593 4.91421 31.5593C5.41544 31.5593 5.90371 31.4599 6.37038 31.2698C6.83272 31.0797 7.24754 30.7988 7.60186 30.4445C7.95618 30.0902 8.23272 29.6754 8.42717 29.213C8.61729 28.7507 8.71667 28.2581 8.71667 27.7568C8.71667 27.2556 8.61729 26.7673 8.42717 26.3007C8.42717 26.2963 8.42284 26.2877 8.41852 26.2834L10.3198 24.905C10.7043 24.6241 10.7907 24.084 10.5099 23.6994C10.229 23.3149 9.68889 23.2285 9.30432 23.5093V23.5007ZM6.83272 28.5476C6.72902 28.8025 6.57778 29.0272 6.38334 29.2217C6.1889 29.4161 5.96421 29.5673 5.70927 29.671C5.20371 29.8784 4.62902 29.8784 4.12347 29.671C3.87285 29.5673 3.64384 29.4161 3.44939 29.2217C3.25495 29.0272 3.10372 28.8025 3.00001 28.5476C2.89631 28.2926 2.84014 28.0291 2.84014 27.7525C2.84014 27.476 2.89199 27.2124 3.00001 26.9575C3.10804 26.7025 3.25495 26.4778 3.44939 26.2834C3.64384 26.0889 3.86853 25.9377 4.12347 25.834C4.37408 25.7303 4.64199 25.6741 4.91853 25.6741C5.19507 25.6741 5.45865 25.726 5.71359 25.834C5.96421 25.9377 6.19322 26.0889 6.38766 26.2834C6.58211 26.4778 6.73334 26.7025 6.83704 26.9575C6.94075 27.2081 6.99692 27.476 6.99692 27.7525C6.99692 28.0291 6.94507 28.2926 6.83704 28.5476H6.83272Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.5247 8.66242C23.7018 8.66242 23.879 8.61057 24.0302 8.49822L25.9315 7.11983C26.6228 7.72045 27.4957 8.05316 28.4203 8.05316C29.4358 8.05316 30.3907 7.65563 31.108 6.93835C31.8253 6.22107 32.2228 5.26613 32.2228 4.2507C32.2228 3.23527 31.8253 2.28033 31.108 1.56305C30.3907 0.845766 29.4358 0.448236 28.4203 0.448236C27.4049 0.448236 26.45 0.845766 25.7327 1.56305C25.0154 2.28033 24.6179 3.23527 24.6179 4.2507C24.6179 4.7649 24.7216 5.26613 24.916 5.72415L23.0191 7.10255C22.6346 7.38341 22.5481 7.92353 22.8247 8.3081C22.9932 8.54144 23.2568 8.66674 23.5247 8.66674V8.66242ZM26.9555 2.78157C27.3487 2.38836 27.8673 2.17231 28.4203 2.17231C28.9734 2.17231 29.4963 2.38836 29.8852 2.78157C30.2784 3.17477 30.4944 3.69329 30.4944 4.24638C30.4944 4.79946 30.2784 5.3223 29.8852 5.71119C29.4919 6.10008 28.9734 6.32045 28.4203 6.32045C27.8673 6.32045 27.3444 6.1044 26.9555 5.71119C26.5623 5.31798 26.3463 4.79946 26.3463 4.24638C26.3463 3.69329 26.5623 3.17045 26.9555 2.78157Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.4203 23.9501C27.4957 23.9501 26.6228 24.2828 25.9315 24.8791L24.0302 23.5007C23.6457 23.2198 23.1055 23.3062 22.8247 23.6908C22.5438 24.0754 22.6302 24.6155 23.0148 24.8964L24.9117 26.2747C24.7173 26.7371 24.6136 27.234 24.6136 27.7482C24.6136 28.7636 25.0111 29.7186 25.7284 30.4359C26.4457 31.1531 27.4006 31.5507 28.416 31.5507C29.4315 31.5507 30.3864 31.1531 31.1037 30.4359C31.821 29.7186 32.2185 28.7636 32.2185 27.7482C32.2185 26.7328 31.821 25.7778 31.1037 25.0605C30.3864 24.3433 29.4315 23.9457 28.416 23.9457L28.4203 23.9501ZM29.8852 29.2173C29.4919 29.6105 28.9734 29.8266 28.4203 29.8266C27.8673 29.8266 27.3444 29.6105 26.9555 29.2173C26.5623 28.8241 26.3463 28.3056 26.3463 27.7525C26.3463 27.1994 26.5623 26.6766 26.9555 26.2877C27.3487 25.8945 27.8673 25.6784 28.4247 25.6784C28.9821 25.6784 29.5006 25.8945 29.8895 26.2877C30.2827 26.6809 30.4987 27.1994 30.4987 27.7525C30.4987 28.3056 30.2827 28.8284 29.8895 29.2173H29.8852Z",
    fill: "none"
  }), _jsx("path", {
    d: "M23.408 20.8951C23.408 19.0933 22.708 17.4038 21.4333 16.1291C20.6339 15.3297 19.666 14.755 18.6074 14.4396C19.0049 14.2365 19.3765 13.9729 19.7006 13.6488C20.5129 12.8365 20.958 11.7606 20.958 10.6155C20.958 9.47045 20.5 8.38156 19.7006 7.58218C18.8883 6.76983 17.8123 6.32477 16.6673 6.32477C15.5222 6.32477 14.442 6.76983 13.6339 7.58218C12.8259 8.39452 12.3765 9.48773 12.3765 10.6155C12.3765 11.7433 12.8216 12.8408 13.6339 13.6532C13.958 13.9772 14.3296 14.2408 14.7272 14.4439C13.6728 14.7593 12.7049 15.334 11.9012 16.1334C10.6265 17.4081 9.92654 19.0976 9.92654 20.8994C9.92654 21.3748 10.3154 21.7636 10.7907 21.7636H22.5438C23.0191 21.7636 23.408 21.3748 23.408 20.8994V20.8951ZM14.8525 8.80069C15.3364 8.31674 15.9802 8.04884 16.6673 8.04884C17.3543 8.04884 17.9981 8.31674 18.4821 8.80069C18.966 9.28464 19.2339 9.93711 19.2339 10.6155C19.2339 11.2939 18.966 11.9464 18.4821 12.4303C17.9981 12.9143 17.3543 13.1822 16.6673 13.1822C15.9802 13.1822 15.3364 12.9143 14.8525 12.4303C14.3685 11.9464 14.1006 11.3025 14.1006 10.6155C14.1006 9.92847 14.3728 9.28032 14.8525 8.80069ZM11.7284 20.0352C11.9012 19.0241 12.3809 18.0951 13.1241 17.3562C14.0704 16.4099 15.3278 15.8871 16.6673 15.8871C18.0068 15.8871 19.2642 16.4099 20.2105 17.3562C20.9494 18.0951 21.429 19.0285 21.6062 20.0352H11.7284Z",
    fill: "none"
  })]
}), 'UserTarget', 'Brandbook');
export default UserTarget;