/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var NamingConventionsIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M2 4H17V6H2V4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.4645 18.5355C12.4021 19.4732 13.6739 20 15 20C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15H22C21.9931 16.5838 21.4493 18.1185 20.4575 19.3533C19.4656 20.5881 18.0843 21.4501 16.5392 21.7983C14.9941 22.1466 13.3766 21.9606 11.9509 21.2707C10.5252 20.5807 9.37566 19.4277 8.69 18H2V16H8.08C7.97333 15.3376 7.97333 14.6624 8.08 14H2V12H8.69C9.03702 11.2521 9.51832 10.5742 10.11 10H2V8H15V10C13.6739 10 12.4021 10.5268 11.4645 11.4645C10.5268 12.4021 10 13.6739 10 15C10 16.3261 10.5268 17.5979 11.4645 18.5355Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22 10C22 10.5523 21.5523 11 21 11C20.4477 11 20 10.5523 20 10C20 9.44772 20.4477 9 21 9C21.5523 9 22 9.44772 22 10Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.71 12.29L11.29 13.71L15 17.41L19.71 12.71L18.29 11.29L15 14.59L12.71 12.29Z",
    fill: "none"
  })]
}), 'NamingConventionsIcon', 'Brandbook');
export default NamingConventionsIcon;