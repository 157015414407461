/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ShoppingCartIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12.333 15a1 1 0 100-2 1 1 0 000 2z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.412 1.979h10.242v4h4.7v16.042H2.312V5.98h5.1v-4zm8.2 2.042V5.98H9.454V4.021h6.158zm-11.258 4V19.98h15.958V8.021H4.354z",
    fill: "none"
  })]
}), 'ShoppingCartIcon', 'Brandbook');
export default ShoppingCartIcon;