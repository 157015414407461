/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ChatbotIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M21.333 4h-6v2h6V4z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M24.333 0v10h-7.697l-4.303 2.868V0h12zm-10 9.131V2h8v6h-6.302l-1.698 1.131z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.333 5a1 1 0 100 2 1 1 0 000-2zM7.333 14h-2v2h2v-2z",
    fill: "none"
  }), _jsx("path", {
    d: "M10.333 9h-2v1h-7v4h-1v6h1v4h16v-4h1v-6h-1v-1h-2v9h-12V12h7V9z",
    fill: "none"
  }), _jsx("path", {
    d: "M13.333 14h-2v2h2v-2zM12.333 18v2h-6v-2h6z",
    fill: "none"
  })]
}), 'ChatbotIcon', 'Brandbook');
export default ChatbotIcon;