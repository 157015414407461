/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UsingSegmentIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.21 15.0001L11.77 17.8101L15.29 14.2901L16.71 15.7101L12.23 20.1901L4.79 17.0001H3V20.0001H1V5.00011H3V7.38011L9.55 4.11011L10.45 5.89011L3 9.62011V15.0001H5.21Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21 5.00018V6.77018L15.98 4.93018L10 7.32018V14.0002H12V8.68018L16.02 7.07018L21 8.90018V16.0002H23V5.00018H21Z",
    fill: "none"
  }), _jsx("path", {
    d: "M22 20C22.5523 20 23 19.5523 23 19C23 18.4477 22.5523 18 22 18C21.4477 18 21 18.4477 21 19C21 19.5523 21.4477 20 22 20Z",
    fill: "none"
  })]
}), 'UsingSegmentIcon', 'Brandbook');
export default UsingSegmentIcon;