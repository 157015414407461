/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DownloadCloudIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M18.79 6.34001C18.4107 4.82642 17.5365 3.48298 16.3063 2.52305C15.0761 1.56312 13.5604 1.04175 12 1.04175C10.4396 1.04175 8.92393 1.56312 7.69373 2.52305C6.46354 3.48298 5.58934 4.82642 5.21 6.34001C4.26686 6.70162 3.45536 7.3404 2.88236 8.17223C2.30936 9.00406 2.00174 9.98993 2 11C1.99975 11.808 2.19533 12.6041 2.57 13.32L4.34 12.39C4.11621 11.9609 3.99955 11.484 4 11C4.00161 10.3273 4.22926 9.67471 4.64638 9.14696C5.0635 8.61922 5.64588 8.24697 6.3 8.09001L7 7.93001L7.1 7.27001C7.28415 6.09208 7.88314 5.01872 8.78895 4.24351C9.69476 3.4683 10.8478 3.04228 12.04 3.04228C13.2322 3.04228 14.3852 3.4683 15.291 4.24351C16.1969 5.01872 16.7959 6.09208 16.98 7.27001L17.08 7.93001L17.74 8.09001C18.2865 8.22286 18.7846 8.50665 19.1776 8.90899C19.5706 9.31134 19.8425 9.81605 19.9624 10.3655C20.0823 10.915 20.0453 11.4871 19.8557 12.0166C19.6661 12.5461 19.3315 13.0116 18.89 13.36C18.3515 13.7828 17.6846 14.0086 17 14V16C18.1642 15.9949 19.2902 15.5836 20.1836 14.8371C21.0771 14.0907 21.682 13.0558 21.8941 11.9111C22.1063 10.7663 21.9123 9.58341 21.3456 8.56639C20.7789 7.54936 19.8751 6.76197 18.79 6.34001Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.29 14.2899C4.15097 14.4301 4.05654 14.6083 4.01862 14.8021C3.9807 14.9958 4.00098 15.1965 4.07691 15.3787C4.15283 15.561 4.28101 15.7167 4.44528 15.8262C4.60955 15.9357 4.80257 15.9941 5 15.9941C5.19743 15.9941 5.39045 15.9357 5.55472 15.8262C5.71899 15.7167 5.84717 15.561 5.92309 15.3787C5.99902 15.1965 6.0193 14.9958 5.98138 14.8021C5.94346 14.6083 5.84903 14.4301 5.71 14.2899C5.61704 14.1962 5.50644 14.1218 5.38458 14.071C5.26272 14.0203 5.13201 13.9941 5 13.9941C4.86799 13.9941 4.73728 14.0203 4.61542 14.071C4.49356 14.1218 4.38296 14.1962 4.29 14.2899Z",
    fill: "none"
  }), _jsx("path", {
    d: "M13 10V18.59L15.29 16.29L16.71 17.71L12 22.41L7.29004 17.71L8.71004 16.29L11 18.59V10H13Z",
    fill: "none"
  })]
}), 'DownloadCloudIcon', 'Brandbook');
export default DownloadCloudIcon;