/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CompliantIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12 0.88208L17.4472 3.60569L16.5528 5.39454L12 3.11815L5 6.61815V12.0001C5 14.5537 6.71825 16.7998 8.6585 18.4975C9.60762 19.328 10.5615 19.9843 11.28 20.4334C11.564 20.6109 11.8097 20.755 12.0015 20.8637C12.1943 20.7556 12.4411 20.6126 12.7264 20.4372C13.4474 19.9941 14.4038 19.351 15.3551 18.5483C17.3156 16.8942 19 14.7777 19 12.5001V9.86701L21 9.86637V12.5001C21 15.7225 18.6844 18.356 16.6449 20.0769C15.5962 20.9617 14.5526 21.6624 13.7736 22.1411C13.383 22.3812 13.0563 22.5671 12.8251 22.694C12.7094 22.7576 12.6175 22.8064 12.5532 22.84C12.4233 22.9079 12.2919 22.9731 12.1605 23.0383C12.107 23.0648 12.0534 23.0914 12 23.1181C11.9465 23.0914 11.8929 23.0648 11.8393 23.0382C11.7075 22.9729 11.5756 22.9075 11.4453 22.8391C11.3806 22.8051 11.2881 22.7557 11.1719 22.6914C10.9397 22.5628 10.6118 22.3742 10.22 22.1294C9.43854 21.641 8.39238 20.9222 7.3415 20.0027C5.28175 18.2004 3 15.4466 3 12.0001V5.38208L12 0.88208Z",
    fill: "none"
  }), _jsx("path", {
    d: "M11.71 15.41L8 11.71L9.42 10.29L11.71 12.59L15.71 8.5L17.13 9.92L11.71 15.41Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19.5 7.5C20.0523 7.5 20.5 7.0523 20.5 6.5C20.5 5.94772 20.0523 5.5 19.5 5.5C18.9477 5.5 18.5 5.94772 18.5 6.5C18.5 7.0523 18.9477 7.5 19.5 7.5Z",
    fill: "none"
  })]
}), 'CompliantIcon', 'Brandbook');
export default CompliantIcon;