/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FormFields = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.4669 2.36575C25.1387 2.37273 26.7398 3.03992 27.922 4.22196C29.104 5.40409 29.7712 7.00524 29.7782 8.67691V8.68415C29.7712 10.3558 29.104 11.9571 27.922 13.1391C26.7398 14.3212 25.1386 14.9883 23.4669 14.9954H8.53382C6.86214 14.9883 5.261 14.3212 4.07886 13.1391C2.89682 11.9571 2.22963 10.3558 2.22266 8.68415V8.67691C2.22963 7.00524 2.89682 5.40409 4.07886 4.22196C5.261 3.03992 6.86214 2.37273 8.53382 2.36575H23.4669ZM3.94488 8.68053C3.9509 9.89668 4.43666 11.0613 5.29665 11.9213C6.15715 12.7818 7.32258 13.2676 8.53933 13.2732H23.4614C24.6782 13.2676 25.8437 12.7818 26.7041 11.9213C27.5642 11.0613 28.0499 9.89668 28.056 8.68053C28.0499 7.46447 27.5642 6.29982 26.7041 5.43983C25.8437 4.57932 24.6782 4.09348 23.4615 4.08797H8.53933C7.32258 4.09348 6.15715 4.57932 5.29665 5.43983C4.43666 6.29982 3.9509 7.46447 3.94488 8.68053Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.4669 17.0046C25.1386 17.0116 26.7398 17.6788 27.922 18.8608C29.104 20.043 29.7712 21.6441 29.7782 23.3158L29.7776 23.2491L29.7782 23.323C29.7712 24.9947 29.104 26.5959 27.922 27.778C26.7398 28.9601 25.1386 29.6272 23.4669 29.6343H8.53382C6.86214 29.6272 5.261 28.9601 4.07886 27.778C2.89682 26.5959 2.22963 24.9947 2.22266 23.323V23.3158C2.22963 21.6441 2.89682 20.043 4.07886 18.8608C5.261 17.6788 6.86214 17.0116 8.53382 17.0046H23.4669ZM3.94488 23.3194C3.9509 24.5355 4.43666 25.7002 5.29665 26.5602C6.15715 27.4207 7.32258 27.9064 8.53933 27.912H23.4614C24.6782 27.9064 25.8437 27.4207 26.7041 26.5602C27.5642 25.7002 28.0499 24.5355 28.056 23.3194C28.0499 22.1033 27.5642 20.9387 26.7041 20.0787C25.8437 19.2182 24.6782 18.7324 23.4615 18.7268H8.53933C7.32258 18.7324 6.15715 19.2182 5.29665 20.0787C4.43666 20.9387 3.9509 22.1033 3.94488 23.3194Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.086 6.9235C11.4222 6.58724 11.9675 6.58724 12.3037 6.9235C12.64 7.25976 12.64 7.80502 12.3037 8.14128L9.7204 10.7246C9.38414 11.0609 8.83889 11.0609 8.50262 10.7246L6.7804 9.00239C6.44414 8.66613 6.44414 8.12087 6.7804 7.78461C7.11667 7.44835 7.66192 7.44835 7.99818 7.78461L9.11151 8.89794L11.086 6.9235Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M24.0026 21.5624C24.3389 21.2261 24.8841 21.2261 25.2204 21.5624C25.5566 21.8986 25.5566 22.4439 25.2204 22.7802L22.6371 25.3635C22.3008 25.6997 21.7555 25.6997 21.4193 25.3635L19.6971 23.6413C19.3608 23.305 19.3608 22.7597 19.6971 22.4235C20.0333 22.0872 20.5786 22.0872 20.9148 22.4235L22.0282 23.5368L24.0026 21.5624Z",
    fill: "none"
  })]
}), 'FormFields', 'Brandbook');
export default FormFields;