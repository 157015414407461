/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DataIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.333 1c-2.563 0-4.924.345-6.68.93-.873.29-1.65.66-2.231 1.117-.57.449-1.089 1.101-1.089 1.953 0 .033.001.066.003.098a1.017 1.017 0 00-.003.068V19c0 .85.513 1.502 1.082 1.953.58.459 1.355.827 2.227 1.118 1.752.585 4.114.929 6.691.929s4.94-.344 6.692-.929c.348-.116.681-.245.992-.387l-1.54-1.54-.085.03c-1.498.5-3.635.826-6.059.826-2.423 0-4.56-.326-6.058-.826-.754-.252-1.29-.53-1.618-.789a1.224 1.224 0 01-.288-.3.298.298 0 01-.032-.067.06.06 0 01-.004-.018v-4.468c.398.203.84.383 1.309.539 1.752.585 4.114.929 6.691.929s4.94-.344 6.692-.929c.469-.156.91-.336 1.308-.54V16h2V5.166c0-.023 0-.046-.002-.068.002-.032.002-.065.002-.098 0-.852-.519-1.504-1.088-1.953-.58-.458-1.359-.826-2.232-1.117-1.755-.585-4.117-.93-6.68-.93zm-8 4l.003-.015a.295.295 0 01.032-.066c.043-.07.13-.174.291-.301.33-.26.87-.539 1.627-.79C7.787 3.326 9.926 3 12.333 3c2.408 0 4.546.327 6.048.827.756.252 1.296.53 1.626.79.162.128.249.232.291.302.02.033.029.055.032.066l.003.015-.003.015a.297.297 0 01-.032.066c-.042.07-.129.174-.291.301-.33.26-.87.539-1.626.79-1.502.501-3.64.828-6.048.828-2.407 0-4.546-.327-6.047-.827-.757-.252-1.296-.53-1.627-.79a1.236 1.236 0 01-.29-.302.295.295 0 01-.033-.066L4.333 5zm0 7V7.527c.401.205.847.385 1.32.543 1.756.585 4.117.93 6.68.93 2.563 0 4.925-.345 6.68-.93a9.538 9.538 0 001.32-.543V12c0 .002 0 .007-.003.018a.302.302 0 01-.032.067c-.042.07-.128.173-.289.3-.327.26-.864.537-1.617.789-1.498.5-3.635.826-6.059.826-2.423 0-4.56-.326-6.058-.826-.754-.252-1.29-.53-1.618-.789a1.224 1.224 0 01-.288-.3.298.298 0 01-.032-.067.06.06 0 01-.004-.018z",
    fill: "none"
  }), _jsx("path", {
    d: "M20.333 19a1 1 0 102 0 1 1 0 00-2 0z",
    fill: "none"
  })]
}), 'DataIcon', 'Brandbook');
export default DataIcon;