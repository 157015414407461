/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var GuidesIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    clipRule: "evenodd",
    d: "M10.6675 19.1822C9.00212 18.0072 6.6293 17.8127 5 17.8446v2.1565l5.6675-.8189ZM3 18.0137l-.999.1641L2 13.0001h2v2.8848c2.39517-.2366 4.81028.165 7 1.164V6.35068c-2.0215-2.3618-5.5264-1.9048-7-1.5791v2.3628H2v-3.855l.6836-.228C2.93677 2.96767 8.4854 1.17205 12 4.50034c3.5145-3.32829 9.0623-1.53267 9.3164-1.44896l.6836.228V18.1778l-1-.164v4.119l-1.124-.1405-7.8663-.9833-7.86669 1.1367L3 22.3109v-4.2972Zm16 1.8535-5.6386-.7048c1.6639-1.1571 4.0187-1.3495 5.6386-1.3178v2.0226Zm-6-2.8183c2.1899-.9983 4.6049-1.3999 7-1.164V4.77158c-1.4736-.3252-4.9785-.7827-7 1.5791V17.0489ZM3 11c.55228 0 1-.4477 1-1 0-.55228-.44772-1-1-1s-1 .44772-1 1c0 .5523.44772 1 1 1Z"
  })
}), 'GuidesIcon', 'Brandbook');
export default GuidesIcon;