/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MobileIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M6 4H18V20H6V12H4V22H20V2H4V6H6V4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6 9C6 9.5523 5.55228 10 5 10C4.44772 10 4 9.5523 4 9C4 8.44772 4.44772 8 5 8C5.55228 8 6 8.44772 6 9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M10 18H14V16H10V18Z",
    fill: "none"
  })]
}), 'MobileIcon', 'Brandbook');
export default MobileIcon;