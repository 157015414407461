/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityEmissions2020Icon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M7 17C7.55228 17 8 16.5523 8 16C8 15.4477 7.55228 15 7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.001 21.0001C18.5533 21.0001 19.001 20.5524 19.001 20.0001C19.001 19.4478 18.5533 19.0001 18.001 19.0001C17.4487 19.0001 17.001 19.4478 17.001 20.0001C17.001 20.5524 17.4487 21.0001 18.001 21.0001Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.6524 3.0015C11.4857 2.96279 10.3496 3.37936 9.48441 4.16311C8.61922 4.94686 8.09274 6.03637 8.01629 7.20126L7.96857 7.92846L7.26169 8.10574C6.74858 8.23442 6.27881 8.49699 5.90037 8.86663C5.52194 9.23626 5.24839 9.69972 5.10767 10.2097C4.96696 10.7196 4.96412 11.2578 5.09944 11.7692C5.23476 12.2806 5.50339 12.7469 5.8779 13.1205L4.46538 14.5364C3.84025 13.9128 3.39185 13.1344 3.16598 12.2808C2.9401 11.4272 2.94485 10.5289 3.17973 9.67767C3.41461 8.82647 3.87121 8.05287 4.50289 7.43588C4.96484 6.98466 5.50833 6.62904 6.10202 6.38649C6.34832 4.96485 7.06269 3.65828 8.14167 2.68086C9.39167 1.54852 11.033 0.946678 12.7187 1.0026C14.4044 1.05852 16.0023 1.76781 17.1745 2.98051C18.2312 4.07365 18.8673 5.49857 18.9814 7.00425C19.7034 7.53916 20.2706 8.26163 20.6182 9.09804C21.0404 10.1142 21.1148 11.2414 20.8298 12.3043C20.5447 13.3671 19.9162 14.3058 19.0421 14.9743C18.1685 15.6424 17.0985 16.003 15.9987 16.0001H15.4143L16.7071 17.293L15.2929 18.7072L10.5858 14.0001H16L16.003 14.0001C16.6622 14.0021 17.3035 13.786 17.8272 13.3856C18.3508 12.9852 18.7273 12.4228 18.898 11.7862C19.0688 11.1495 19.0242 10.4742 18.7713 9.8655C18.5183 9.25679 18.0712 8.74881 17.4995 8.42066L16.9909 8.1287L16.9974 7.54227C16.9977 7.51677 16.9987 7.49539 16.9996 7.48088L17 7.47476C16.9946 6.31618 16.5422 5.20401 15.7365 4.37051C14.9252 3.53114 13.8192 3.0402 12.6524 3.0015Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.2939 22.7072L8.29395 18.7072L9.70816 17.293L13.7082 21.293L12.2939 22.7072Z",
    fill: "none"
  })]
}), 'SustainabilityEmissions2020Icon', 'Brandbook');
export default SustainabilityEmissions2020Icon;