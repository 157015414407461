/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var IndustryHealthIcon = createIconsComponent(_jsx("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M.54 7.968c.05-.212.111-.421.182-.626l1.951.487a4.538 4.538 0 00-.188.602l-.002.01-.003.01a4.972 4.972 0 00-.146 1.275v.03c0 .402.048.8.144 1.186.103.394.25.773.436 1.13.195.346.43.664.698.946l8.721 8.232 1.92-1.813 1.466 1.367L12.333 24l-10.13-9.562a6.763 6.763 0 01-1.046-1.412 7.327 7.327 0 01-.617-1.59 6.93 6.93 0 01-.206-1.68A6.973 6.973 0 01.54 7.968zM23.57 12.883a6.612 6.612 0 00.762-3.127 6.967 6.967 0 00-.205-1.788 6.384 6.384 0 00-.634-1.608c-.272-.523-.619-1-1.029-1.412a6.469 6.469 0 00-2.074-1.447 6.17 6.17 0 00-4.868 0 6.817 6.817 0 00-1.989 1.34l-.085.107-1.115 1.162-1.114-1.162-.086-.107A6.89 6.89 0 009.145 3.5a6.17 6.17 0 00-3.302-.44l.489 1.956c.68-.062 1.37.044 2.016.318.475.21.918.502 1.31.863l.055.07 2.62 2.731 2.62-2.732.056-.069a4.825 4.825 0 011.31-.863 4.17 4.17 0 013.278.002 4.471 4.471 0 011.426.998l.011.012.012.011c.265.267.492.578.671.923l.016.03.016.029c.193.343.34.715.435 1.104l.002.008c.104.415.153.844.147 1.274V9.768c.003.579-.101 1.149-.303 1.678l1.54 1.437zM2.333 5.111a1.111 1.111 0 112.222 0 1.111 1.111 0 01-2.222 0zM20.333 12h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z",
    fill: "none"
  })
}), 'IndustryHealthIcon', 'Brandbook');
export default IndustryHealthIcon;