/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var EmbedIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.159 14.698a3 3 0 1 1-3.21 1.665l-2.25-2.249 2.122-2.121-2.828-2.829-2.829 2.829 2.122 2.121-1.415 1.414-.834.835a3 3 0 1 1-1.414-1.414l.834-.835-2.121-2.121 2.121-2.121-.834-.835a3 3 0 1 1 1.414-1.414l.834.834 2.122-2.121 2.121 2.121 1.414-1.414 1.415 1.414-1.415 1.415 2.122 2.12-2.122 2.122.835.835a3 3 0 0 1 1.796-.25ZM5.355 6.531a1 1 0 1 0 1.962-.39 1 1 0 0 0-1.962.39Zm1.962 10.924a1 1 0 1 0-1.962.39 1 1 0 0 0 1.962-.39Zm9.352.39a1 1 0 1 0 1.962-.39 1 1 0 0 0-1.962.39Zm2.133-11.247a1 1 0 1 1-1.414-1.415 1 1 0 0 1 1.414 1.415Z"
  })
}), 'EmbedIcon', 'Brandbook');
export default EmbedIcon;