/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var DataCollectIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M22 7V1H2V9V11V19H11V23H13V19H22V17H4V11H20V15H22V11V9H4V3H20V7H22ZM9.99 5H6.01V7H9.99V5ZM13.99 6C13.99 6.55228 13.5423 7 12.99 7C12.4377 7 11.99 6.55228 11.99 6C11.99 5.44772 12.4377 5 12.99 5C13.5423 5 13.99 5.44772 13.99 6ZM16.99 7C17.5423 7 17.99 6.55228 17.99 6C17.99 5.44772 17.5423 5 16.99 5C16.4377 5 15.99 5.44772 15.99 6C15.99 6.55228 16.4377 7 16.99 7ZM6.01 13H9.99V15H6.01V13ZM12.99 15C13.5423 15 13.99 14.5523 13.99 14C13.99 13.4477 13.5423 13 12.99 13C12.4377 13 11.99 13.4477 11.99 14C11.99 14.5523 12.4377 15 12.99 15ZM17.99 14C17.99 14.5523 17.5423 15 16.99 15C16.4377 15 15.99 14.5523 15.99 14C15.99 13.4477 16.4377 13 16.99 13C17.5423 13 17.99 13.4477 17.99 14Z",
    fill: "none"
  })
}), 'DataCollectIcon', 'Brandbook');
export default DataCollectIcon;