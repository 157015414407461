/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ChatIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M22 3C22.5523 3 23 2.55228 23 2C23 1.44772 22.5523 1 22 1C21.4477 1 21 1.44772 21 2C21 2.55228 21.4477 3 22 3ZM9 9C9.55228 9 10 8.55228 10 8C10 7.44772 9.55228 7 9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9ZM13 9H12V7H15V20H5.3L1 22.87V7H6V9H3V19.13L4.7 18H13V9ZM10 3H19V1H8V5H10V3ZM19.3 12L21 13.13V5H23V16.87L18.7 14H17V12H19.3ZM4 11V13H12V11H4ZM4 16V14H12V16H4Z",
    fill: "none"
  })
}), 'ChatIcon', 'Brandbook');
export default ChatIcon;