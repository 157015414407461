/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityFootprintIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14.3301 17.4286C14.8824 17.4286 15.3301 16.9809 15.3301 16.4286C15.3301 15.8763 14.8824 15.4286 14.3301 15.4286C13.7778 15.4286 13.3301 15.8763 13.3301 16.4286C13.3301 16.9809 13.7778 17.4286 14.3301 17.4286Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.15447 6.91936C8.48106 6.63535 7.74782 6.52241 7.02015 6.59062C6.29248 6.65883 5.59301 6.90608 4.98411 7.3103C4.37521 7.71453 3.87582 8.26316 3.53047 8.90728C3.18513 9.5514 3.00456 10.271 3.00488 11.0018C3.0052 11.7327 3.1864 12.4521 3.53231 13.0959C3.87823 13.7397 4.3781 14.2879 4.98736 14.6916C5.59661 15.0953 6.2963 15.3419 7.02403 15.4095C7.75176 15.4771 8.4849 15.3635 9.15806 15.0789L9.9369 16.921C8.95973 17.3342 7.89549 17.499 6.83912 17.401C5.78274 17.3029 4.76706 16.9449 3.88266 16.3589C2.99826 15.7729 2.27264 14.9771 1.77051 14.0425C1.26838 13.108 1.00535 12.0636 1.00488 11.0027C1.00442 9.9418 1.26653 8.89725 1.76783 7.96224C2.26914 7.02723 2.99406 6.23082 3.87795 5.64405C4.76183 5.05727 5.77719 4.69837 6.83348 4.59935C7.88978 4.50033 8.95415 4.66428 9.93169 5.07656L9.15447 6.91936Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.1576 6.61014C16.4525 6.51642 15.7352 6.59392 15.0664 6.8361C14.3975 7.07827 13.7969 7.47798 13.3152 8.00142C12.8336 8.52485 12.4851 9.1566 12.2993 9.84322C12.1135 10.5298 12.0958 11.2511 12.2477 11.946C12.3996 12.6409 12.7167 13.289 13.1721 13.8354L11.6357 15.1159C10.9747 14.3227 10.5144 13.3819 10.2938 12.3732C10.0733 11.3644 10.099 10.3174 10.3687 9.32074C10.6385 8.32404 11.1443 7.40699 11.8435 6.64717C12.5427 5.88734 13.4146 5.30711 14.3855 4.95558C15.3563 4.60404 16.3976 4.49153 17.4211 4.62758C18.4447 4.76363 19.4204 5.14423 20.2657 5.73717C21.1111 6.33011 21.8011 7.11796 22.2775 8.03403C22.754 8.95011 23.0027 9.96747 23.0027 11L21.0027 11.0001C21.0027 10.2887 20.8313 9.58789 20.5031 8.95682C20.175 8.32574 19.6996 7.783 19.1172 7.37453C18.5349 6.96606 17.8627 6.70387 17.1576 6.61014Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.5016 14C19.2049 14 18.9149 14.088 18.6682 14.2528C18.4216 14.4176 18.2293 14.6519 18.1158 14.926C18.0022 15.2 17.9725 15.5016 18.0304 15.7926C18.0883 16.0836 18.2311 16.3509 18.4409 16.5606L17.0267 17.9748C16.5372 17.4853 16.2039 16.8617 16.0688 16.1828C15.9338 15.5038 16.0031 14.8001 16.268 14.1606C16.5329 13.521 16.9815 12.9744 17.5571 12.5898C18.1327 12.2053 18.8094 12 19.5016 12C20.1938 12 20.8705 12.2053 21.4461 12.5898C22.0216 12.9744 22.4702 13.521 22.7352 14.1606C23.0001 14.8001 23.0694 15.5038 22.9343 16.1828C22.8016 16.85 22.4774 17.4637 22.0018 17.9493L20.2063 19.9999H22.5916V21.9999H15.7969L20.5388 16.5841L20.5623 16.5606C20.772 16.3509 20.9149 16.0836 20.9728 15.7926C21.0306 15.5016 21.0009 15.2 20.8874 14.926C20.7739 14.6519 20.5816 14.4176 20.3349 14.2528C20.0883 14.088 19.7983 14 19.5016 14Z",
    fill: "none"
  })]
}), 'SustainabilityFootprintIcon', 'Brandbook');
export default SustainabilityFootprintIcon;