/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SustainabilityEmissions2019Icon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.999 15C14.5513 15 14.999 14.5523 14.999 14C14.999 13.4477 14.5513 13 13.999 13C13.4467 13 12.999 13.4477 12.999 14C12.999 14.5523 13.4467 15 13.999 15Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.18494 15.7706L3.20718 13.7928L1.79297 15.2071L5.18521 18.5993L8.57631 15.2069L7.16184 13.793L5.18494 15.7706Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.8157 16.7706L20.7934 14.7928L22.2077 16.2071L18.8154 19.5993L15.4243 16.2069L16.8388 14.793L18.8157 16.7706Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.4996 20.1606L9.16982 17.8301L7.75537 19.2441L11.4996 22.9895L15.2438 19.2441L13.8294 17.8301L11.4996 20.1606Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.1989 3.97412C13.4053 3.62001 12.5184 3.53193 11.6706 3.72303C10.8229 3.91413 10.0596 4.37416 9.49461 5.03452C8.92964 5.69487 8.59327 6.52016 8.53568 7.38731L8.44812 8.7055L7.20321 8.26337C6.95564 8.17544 6.69534 8.12872 6.43267 8.12504C5.7883 8.12657 5.17066 8.38321 4.7149 8.83896C4.25778 9.29608 4.00098 9.91607 4.00098 10.5625V13H2.00098V10.5625C2.00098 9.38563 2.4685 8.25694 3.30069 7.42475C4.13288 6.59255 5.26158 6.12503 6.43848 6.12503H6.45007L6.45007 6.1251C6.54056 6.12615 6.6309 6.12999 6.72096 6.13659C6.95005 5.2558 7.37688 4.43331 7.9749 3.73433C8.82024 2.74626 9.96234 2.05792 11.2309 1.77198C12.4994 1.48605 13.8263 1.61783 15.0138 2.14768C15.9941 2.58505 16.8359 3.2743 17.4568 4.13999C17.9605 4.11735 18.466 4.16654 18.958 4.28706C19.6732 4.46226 20.3441 4.78435 20.9281 5.23286C21.5121 5.68137 21.9963 6.24649 22.3501 6.89228C22.7039 7.53807 22.9194 8.2504 22.983 8.98399C23.0466 9.71758 22.9568 10.4564 22.7195 11.1534C22.4821 11.8504 22.1023 12.4905 21.6042 13.0328C21.1061 13.5751 20.5006 14.0078 19.8262 14.3034C19.1518 14.5991 18.4233 14.7512 17.6869 14.75L17.69 12.75C18.1489 12.7507 18.6029 12.656 19.0232 12.4717C19.4434 12.2875 19.8208 12.0178 20.1312 11.6798C20.4416 11.3419 20.6783 10.943 20.8262 10.5087C20.9742 10.0743 21.0301 9.61387 20.9905 9.1567C20.9508 8.69954 20.8165 8.25562 20.5961 7.85317C20.3756 7.45072 20.0738 7.09854 19.7099 6.81904C19.3459 6.53953 18.9278 6.33881 18.4821 6.22963C18.0364 6.12044 17.5729 6.1052 17.121 6.18485L16.4212 6.30819L16.0745 5.68798C15.6505 4.9294 14.9925 4.32823 14.1989 3.97412Z",
    fill: "none"
  })]
}), 'SustainabilityEmissions2019Icon', 'Brandbook');
export default SustainabilityEmissions2019Icon;