/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MapPinIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M12 1.00781C14.385 1.00781 16.6725 1.95449 18.36 3.63989C20.0454 5.3274 20.9921 7.61489 20.9921 9.99989C20.9921 12.3849 20.0454 14.6724 18.36 16.3599L12 22.7299L5.68999 16.4099C4.06621 14.7904 3.12193 12.6121 3.04999 10.3199L5.04999 10.2599C5.09083 12.0365 5.80583 13.731 7.04999 14.9999L12 19.8999L17 14.9999C18.3194 13.6804 19.0607 11.8909 19.0607 10.0249C19.0607 8.15891 18.3194 6.36935 17 5.04989C15.6805 3.73044 13.891 2.98918 12.025 2.98918C10.159 2.98918 8.36944 3.73044 7.04999 5.04989L5.63999 3.63989C7.32749 1.95449 9.61498 1.00781 12 1.00781Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5 8C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.83 12.83C15.4847 12.176 15.8924 11.3153 15.9836 10.3944C16.0749 9.47352 15.844 8.5495 15.3304 7.77976C14.8167 7.01002 14.0521 6.44218 13.1667 6.17299C12.2814 5.90379 11.33 5.9499 10.4749 6.30346C9.61969 6.65701 8.91355 7.29614 8.47675 8.11195C8.03996 8.92775 7.89954 9.86977 8.07941 10.7775C8.25928 11.6852 8.74832 12.5025 9.46321 13.0901C10.1781 13.6777 11.0746 13.9993 12 14C12.5254 14.0004 13.0458 13.8973 13.5314 13.6965C14.017 13.4958 14.4582 13.2013 14.83 12.83ZM9.99997 10C9.99859 9.67049 10.0786 9.34575 10.233 9.05464C10.3874 8.76353 10.6113 8.51507 10.8848 8.33134C11.1583 8.14762 11.473 8.03433 11.8009 8.00153C12.1288 7.96874 12.4597 8.01746 12.7642 8.14337C13.0687 8.26928 13.3374 8.46847 13.5463 8.72324C13.7553 8.97802 13.8981 9.28047 13.962 9.60372C14.0259 9.92697 14.0089 10.261 13.9126 10.5761C13.8163 10.8912 13.6437 11.1777 13.41 11.41C13.0352 11.7825 12.5283 11.9916 12 11.9916C11.4716 11.9916 10.9647 11.7825 10.59 11.41C10.2144 11.0366 10.0022 10.5296 9.99997 10Z",
    fill: "none"
  })]
}), 'MapPinIcon', 'Brandbook');
export default MapPinIcon;