/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MailboxIcon = createIconsComponent(_jsxs("svg", {
  width: 23,
  height: 24,
  viewBox: "0 0 23 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.333 5h-8a5 5 0 00-5 5h2a3 3 0 016 0v7h-6v-1h-2v3h11v4h2v-4h7v-9a5 5 0 00-5-5V3h3V1h-5v4zm0 2v3h2V7a3 3 0 013 3v7h-8v-7a4.978 4.978 0 00-1-3h4z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.333 13a1 1 0 10-2 0 1 1 0 002 0z",
    fill: "none"
  })]
}), 'MailboxIcon', 'Brandbook');
export default MailboxIcon;