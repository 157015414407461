/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var SearchIcon = createIconsComponent(_jsx("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.413 16.66l2.63 2.63-1.45 1.43-2.63-2.63a9.23 9.23 0 01-5.75 2 9.072 9.072 0 01-3-.52l.67-1.88a7.16 7.16 0 10-4.25-4.06l-1.85.75a9.16 9.16 0 1115.63 2.28zm-14.061.142a1 1 0 111.963.384 1 1 0 01-1.963-.384z"
  })
}), 'SearchIcon', 'Brandbook');
export default SearchIcon;