/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ChartIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M15.44 9.266L11.48 2.83 8.086 6.648 9.58 7.977l1.606-1.807 4.04 6.564 4.887-6.11-1.562-1.249-3.112 3.89z",
    fill: "none"
  }), _jsx("path", {
    d: "M2.333 3h2v17h18v2h-20V3z",
    fill: "none"
  }), _jsx("path", {
    d: "M7.333 10a1 1 0 100-2 1 1 0 000 2zM21.333 4a1 1 0 11-2 0 1 1 0 012 0zM8.333 13v5h-2v-5h2zM12.333 11h-2v7h2v-7zM14.333 16h2v2h-2v-2zM20.333 14h-2v4h2v-4z",
    fill: "none"
  })]
}), 'ChartIcon', 'Brandbook');
export default ChartIcon;