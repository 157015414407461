/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FlexibleScheduleIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23 12.9999V14.9999H21.92C21.7516 16.1533 21.2979 17.2464 20.6 18.1799L21.71 19.2899L20.29 20.7099L19.18 19.5999C18.2464 20.2978 17.1534 20.7515 16 20.9199V21.9999H14V20.9199C12.8485 20.7575 11.7556 20.3107 10.82 19.6199L9.71001 20.7299L8.29001 19.3099L9.40001 18.2099C9.12116 17.8329 8.88315 17.4273 8.69001 16.9999C7.46966 16.9536 6.27611 16.6285 5.20084 16.0495C4.12557 15.4705 3.19712 14.6531 2.48666 13.6598C1.77619 12.6665 1.30256 11.5237 1.10207 10.319C0.901585 9.11437 0.979558 7.87979 1.33001 6.70992L3.25001 7.28992C3.08175 7.84424 2.99748 8.42064 3.00001 8.99992C3.00001 10.1866 3.35191 11.3467 4.0112 12.3333C4.67049 13.32 5.60756 14.0891 6.70391 14.5432C7.80027 14.9973 9.00667 15.1161 10.1706 14.8846C11.3344 14.6531 12.4035 14.0817 13.2427 13.2426C14.0818 12.4035 14.6532 11.3344 14.8847 10.1705C15.1162 9.00658 14.9974 7.80018 14.5433 6.70382C14.0892 5.60747 13.3201 4.6704 12.3334 4.01111C11.3467 3.35182 10.1867 2.99992 9.00001 2.99992C8.42073 2.99739 7.84433 3.08166 7.29001 3.24992L6.71001 1.32992C7.7609 1.01668 8.86495 0.922831 9.95363 1.05419C11.0423 1.18556 12.0924 1.53934 13.0386 2.09356C13.9848 2.64778 14.8069 3.39061 15.454 4.27592C16.1011 5.16123 16.5592 6.17011 16.8 7.23992C17.6599 7.47167 18.4677 7.86538 19.18 8.39992L20.29 7.28992L21.71 8.70992L20.6 9.81992C21.2979 10.7535 21.7516 11.8466 21.92 12.9999H23ZM18.8619 10.7763C18.3564 10.1818 17.7188 9.71386 17 9.40992C16.9098 11.1326 16.2651 12.7799 15.1622 14.1062C14.0593 15.4326 12.5573 16.3669 10.88 16.7699C11.31 17.4212 11.8864 17.9627 12.5632 18.3513C13.2399 18.7398 13.9982 18.9646 14.7774 19.0075C15.5566 19.0505 16.335 18.9105 17.0504 18.5987C17.7658 18.2869 18.3983 17.812 18.8972 17.2119C19.3961 16.6119 19.7477 15.9034 19.9238 15.1432C20.0998 14.3829 20.0955 13.592 19.9111 12.8337C19.7267 12.0754 19.3674 11.3709 18.8619 10.7763Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3 4C3.55228 4 4 3.55228 4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.55 7.83004L11.45 6.17004L9.13004 7.71004L5.71004 4.29004L4.29004 5.71004L8.87004 10.29L12.55 7.83004Z",
    fill: "none"
  })]
}), 'FlexibleScheduleIcon', 'Brandbook');
export default FlexibleScheduleIcon;