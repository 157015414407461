/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BuildingsIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.333 4h-10v16h2v2h-4V2h14v20h-4v-2h2V4z",
    fill: "none"
  }), _jsx("path", {
    d: "M9.333 21a1 1 0 11-2 0 1 1 0 012 0zM5.333 7h2v2h-2V7zM11.333 7h-2v2h2V7zM5.333 11h2v2h-2v-2zM11.333 11h-2v2h2v-2zM5.333 15h2v2h-2v-2zM11.333 15h-2v2h2v-2zM17.333 11h2v2h-2v-2zM19.333 15h-2v2h2v-2z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.333 22v-2h4V8h-4V6h6v16h-6z",
    fill: "none"
  })]
}), 'BuildingsIcon', 'Brandbook');
export default BuildingsIcon;