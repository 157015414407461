/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DollarIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.97594 2.7972C7.76636 1.6249 9.85986 1.00032 11.9999 1C13.9643 1.00045 15.8927 1.52694 17.5848 2.52476C19.2769 3.52258 20.671 4.95532 21.6221 6.67412C22.5732 8.39292 23.0466 10.3351 22.9933 12.2987C22.94 14.2624 22.3617 16.176 21.3187 17.8406C20.2757 19.5052 18.8059 20.8602 17.0621 21.7646C15.3183 22.6691 13.3642 23.0901 11.4026 22.9839C9.44115 22.8777 7.54385 22.2482 5.90792 21.1607C4.27199 20.0732 2.95712 18.5675 2.09992 16.8L3.89992 15.93C4.7955 17.7763 6.29514 19.2612 8.15027 20.1384C10.0054 21.0156 12.1046 21.2326 14.0999 20.7532C16.0952 20.2739 17.8668 19.127 19.121 17.5029C20.3752 15.8787 21.0366 13.8746 20.9958 11.823C20.9549 9.77128 20.2141 7.79518 18.8962 6.22222C17.5784 4.64926 15.7625 3.5739 13.7497 3.1744C11.7369 2.7749 9.64802 3.07524 7.8293 4.02565C6.01058 4.97606 4.57126 6.51946 3.74992 8.4L1.91992 7.6C2.77595 5.6386 4.18553 3.96949 5.97594 2.7972Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11 4V6H10.5C9.57174 6 8.6815 6.36875 8.02513 7.02513C7.36875 7.6815 7 8.57174 7 9.5C7 10.4283 7.36875 11.3185 8.02513 11.9749C8.6815 12.6313 9.57174 13 10.5 13H11V16H8V18H11V20H13V18H13.5C14.4283 18 15.3185 17.6313 15.9749 16.9749C16.6313 16.3185 17 15.4283 17 14.5C17 13.5717 16.6313 12.6815 15.9749 12.0251C15.3185 11.3687 14.4283 11 13.5 11H13V8H16V6H13V4H11ZM13.5 13C13.8978 13 14.2794 13.158 14.5607 13.4393C14.842 13.7206 15 14.1022 15 14.5C15 14.8978 14.842 15.2794 14.5607 15.5607C14.2794 15.842 13.8978 16 13.5 16H13V13H13.5ZM11 11H10.5C10.1022 11 9.72064 10.842 9.43934 10.5607C9.15804 10.2794 9 9.89782 9 9.5C9 9.10218 9.15804 8.72064 9.43934 8.43934C9.72064 8.15804 10.1022 8 10.5 8H11V11Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3 13C3.55228 13 4 12.5523 4 12C4 11.4477 3.55228 11 3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13Z",
    fill: "none"
  })]
}), 'DollarIcon', 'Brandbook');
export default DollarIcon;