/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var WorkFromHomeIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.92 2L2 6.52L2.84 8.34L11.92 4.2L21.01 8.34L21.83 6.52L11.92 2Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.21 16.39C17.071 16.5302 16.9765 16.7084 16.9386 16.9022C16.9007 17.0959 16.921 17.2966 16.9969 17.4788C17.0728 17.6611 17.201 17.8168 17.3653 17.9263C17.5295 18.0358 17.7226 18.0942 17.92 18.0942C18.1174 18.0942 18.3104 18.0358 18.4747 17.9263C18.639 17.8168 18.7671 17.6611 18.8431 17.4788C18.919 17.2966 18.9393 17.0959 18.9014 16.9022C18.8634 16.7084 18.769 16.5302 18.63 16.39C18.537 16.2963 18.4264 16.2219 18.3046 16.1711C18.1827 16.1204 18.052 16.0942 17.92 16.0942C17.788 16.0942 17.6573 16.1204 17.5354 16.1711C17.4135 16.2219 17.3029 16.2963 17.21 16.39Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.84 8.1001V14.1001H16.84V10.1001H7V16.1001H14.92V18.1001H12.88V20.1001H15.92V22.1001H7.92V20.1001H10.88V18.1001H5V8.1001H18.84Z",
    fill: "none"
  })]
}), 'WorkFromHomeIcon', 'Brandbook');
export default WorkFromHomeIcon;