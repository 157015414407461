/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SafeIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20 9.25H19V7.25H22V22.25H2V7.25H7V1.75H17V5.25H15V3.75H9V7.25H13V9.25H4V20.25H8.27788L8.95739 17.1922C8.51268 16.5761 8.25 15.8189 8.25 15C8.25 12.9289 9.92893 11.25 12 11.25C14.0711 11.25 15.75 12.9289 15.75 15C15.75 15.8189 15.4874 16.576 15.0427 17.1921L15.7222 20.25H20V9.25ZM13.7222 20.25L12.904 16.5679L12.8743 16.5165C13.4 16.2124 13.75 15.6465 13.75 15C13.75 14.0335 12.9665 13.25 12 13.25C11.0335 13.25 10.25 14.0335 10.25 15C10.25 15.6465 10.6 16.2124 11.1257 16.5165L11.0962 16.5675L10.2779 20.25H13.7222Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17 8.25C17 8.80228 16.5523 9.25 16 9.25C15.4477 9.25 15 8.80228 15 8.25C15 7.69772 15.4477 7.25 16 7.25C16.5523 7.25 17 7.69772 17 8.25Z",
    fill: "none"
  })]
}), 'SafeIcon', 'Brandbook');
export default SafeIcon;