/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ParentIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.2419 4.70038C20.7406 5.54894 21.0024 6.51576 21 7.49998C21.0006 8.53808 20.7069 9.55507 20.1528 10.433C19.5988 11.3109 18.8073 12.0137 17.87 12.46C18.071 12.5364 18.268 12.6232 18.46 12.72L17.54 14.5C16.8548 14.1515 16.0917 13.9843 15.3236 14.0145C14.5555 14.0446 13.8078 14.271 13.152 14.6721C12.4962 15.0732 11.9541 15.6356 11.5774 16.3057C11.2007 16.9758 11.0019 17.7313 11 18.5V21H9V18.5C9.00051 17.1933 9.39485 15.917 10.1316 14.8378C10.8683 13.7586 11.9132 12.9264 13.13 12.45C12.2418 12.0259 11.4838 11.3712 10.9348 10.5543C10.3859 9.73733 10.0663 8.78806 10.0093 7.80549C9.95223 6.82292 10.1599 5.84305 10.6106 4.9681C11.0614 4.09314 11.7386 3.35517 12.5717 2.83115C13.4049 2.30714 14.3633 2.01629 15.3472 1.98894C16.331 1.96159 17.3042 2.19875 18.1651 2.67568C19.0261 3.1526 19.7433 3.85181 20.2419 4.70038ZM12.5899 5.55549C12.2053 6.13106 12 6.80775 12 7.49998C12 8.42824 12.3687 9.31848 13.0251 9.97486C13.6815 10.6312 14.5717 11 15.5 11C16.1922 11 16.8689 10.7947 17.4445 10.4101C18.0201 10.0255 18.4687 9.47892 18.7336 8.83938C18.9985 8.19983 19.0678 7.4961 18.9327 6.81717C18.7977 6.13823 18.4644 5.51459 17.9749 5.02511C17.4854 4.53563 16.8617 4.20228 16.1828 4.06724C15.5039 3.93219 14.8001 4.0015 14.1606 4.26641C13.5211 4.53131 12.9744 4.97992 12.5899 5.55549Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21 15C21 15.5523 20.5523 16 20 16C19.4477 16 19 15.5523 19 15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15Z",
    fill: "none"
  }), _jsx("path", {
    d: "M20 17V21H22V17H20Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M3.7615 7.6887C4.42224 7.24094 5.20182 7.00109 5.99998 7C7.06084 7 8.07826 7.42143 8.8284 8.17157C9.57855 8.92172 9.99998 9.93913 9.99998 11C9.99998 12.0609 9.57855 13.0783 8.8284 13.8284C8.07826 14.5786 7.06084 15 5.99998 15C5.68589 14.967 5.3684 15.0046 5.0707 15.11C4.773 15.2154 4.50261 15.386 4.27929 15.6093C4.05598 15.8326 3.88538 16.103 3.77996 16.4007C3.67454 16.6984 3.63696 17.0159 3.66998 17.33V21H1.66998V17.33C1.64372 16.6737 1.77213 16.0204 2.04485 15.4228C2.31757 14.8253 2.72696 14.3002 3.23998 13.89C2.6633 13.3382 2.26491 12.6264 2.09602 11.8464C1.92714 11.0663 1.99549 10.2535 2.29229 9.51258C2.58909 8.77165 3.10077 8.13646 3.7615 7.6887ZM4.88884 12.6629C5.21773 12.8827 5.60441 13 5.99998 13C6.53041 13 7.03912 12.7893 7.41419 12.4142C7.78926 12.0391 7.99998 11.5304 7.99998 11C7.99998 10.6044 7.88268 10.2178 7.66291 9.88886C7.44315 9.55996 7.13079 9.30362 6.76534 9.15224C6.39989 9.00087 5.99776 8.96126 5.6098 9.03843C5.22183 9.1156 4.86547 9.30608 4.58576 9.58579C4.30606 9.86549 4.11558 10.2219 4.03841 10.6098C3.96123 10.9978 4.00084 11.3999 4.15222 11.7654C4.30359 12.1308 4.55994 12.4432 4.88884 12.6629Z",
    fill: "none"
  })]
}), 'ParentIcon', 'Brandbook');
export default ParentIcon;