/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UserIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.24 12.2599C15.3042 11.6485 16.1332 10.6988 16.5953 9.56191C17.0574 8.42498 17.126 7.16617 16.7902 5.98577C16.4543 4.80538 15.7333 3.77123 14.7419 3.04788C13.7505 2.32453 12.5456 1.95354 11.319 1.99395C10.0924 2.03436 8.91462 2.48385 7.97296 3.27088C7.03131 4.05792 6.37994 5.13729 6.1225 6.33723C5.86506 7.53717 6.01634 8.78874 6.55227 9.89278C7.0882 10.9968 7.97799 11.8899 9.08004 12.4299C8.14596 12.7212 7.25731 13.1419 6.44004 13.6799L7.56004 15.3499C8.76443 14.5464 10.1643 14.0847 11.6104 14.0142C13.0565 13.9437 14.4947 14.2669 15.7715 14.9495C17.0484 15.6321 18.116 16.6483 18.8607 17.89C19.6054 19.1316 19.9992 20.5521 20 21.9999H22C21.9987 19.7381 21.2307 17.5435 19.8213 15.7745C18.4119 14.0055 16.4444 12.7666 14.24 12.2599ZM8.00004 7.49992C8.00004 6.80768 8.20532 6.13099 8.5899 5.55542C8.97449 4.97985 9.52111 4.53124 10.1607 4.26634C10.8002 4.00143 11.5039 3.93212 12.1829 4.06717C12.8618 4.20222 13.4854 4.53556 13.9749 5.02504C14.4644 5.51453 14.7977 6.13817 14.9328 6.8171C15.0678 7.49603 14.9985 8.19977 14.7336 8.83931C14.4687 9.47885 14.0201 10.0255 13.4445 10.4101C12.869 10.7946 12.1923 10.9999 11.5 10.9999C10.5718 10.9999 9.68155 10.6312 9.02517 9.97479C8.36879 9.31841 8.00004 8.42817 8.00004 7.49992Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5 17C5.55228 17 6 16.5523 6 16C6 15.4477 5.55228 15 5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.83002 18.4398C4.28231 19.5465 3.99823 20.765 4.00002 21.9998H2.00002C1.98356 20.459 2.32593 18.9355 3.00002 17.5498L4.83002 18.4398Z",
    fill: "none"
  })]
}), 'UserIcon', 'Brandbook');
export default UserIcon;