/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TargetIcon = createIconsComponent(_jsxs("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M23.333 2a1 1 0 100-2 1 1 0 000 2zM13.041 12.706a1 1 0 11-1.414-1.414l9-9 1.413 1.415-8.999 9z",
    fill: "none"
  }), _jsx("path", {
    d: "M12.333 21a9 9 0 008.2-12.717l1.565-1.353A10.953 10.953 0 0123.333 12c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.678 0 5.132.957 7.04 2.547L17.95 4.968A9 9 0 1012.333 21z",
    fill: "none"
  }), _jsx("path", {
    d: "M15.81 7.11a6 6 0 102.3 3.265l-1.778 1.535.001.09a4 4 0 11-1.968-3.446l1.445-1.445z",
    fill: "none"
  })]
}), 'TargetIcon', 'Brandbook');
export default TargetIcon;