/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LearningIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12 19.9999C15 19.9999 16.71 19.2599 17 18.9199V16.9999H19V18.9999C19 21.0699 15.48 21.9999 12 21.9999C8.51997 21.9999 4.99997 21.0699 4.99997 18.9999V10.5699L0.0899658 8.3299L12 2.8999L23.91 8.3299L12 13.7699L6.99997 11.4799V18.9199C7.28997 19.2599 8.99997 19.9999 12 19.9999ZM19.09 8.3299L12 5.0999L4.90997 8.3299L12 11.5699L19.09 8.3299Z",
    fill: "none"
  }), _jsx("path", {
    d: "M19 14C19 14.5523 18.5523 15 18 15C17.4477 15 17 14.5523 17 14C17 13.4477 17.4477 13 18 13C18.5523 13 19 13.4477 19 14Z",
    fill: "none"
  })]
}), 'LearningIcon', 'Brandbook');
export default LearningIcon;