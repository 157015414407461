/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AttractingIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.5901 14.49L18.2401 8.83005L23.1901 13.78L17.5401 19.4401C15.852 21.1282 13.5624 22.0765 11.1751 22.0765C8.78772 22.0765 6.49816 21.1282 4.81005 19.4401C3.12195 17.7519 2.17358 15.4624 2.17358 13.075C2.17358 10.6877 3.12195 8.39815 4.81005 6.71005L10.4601 1.05005L12.9401 3.53005L11.5301 4.94005L10.4601 3.88005L9.09005 5.25005L12.6201 8.78005L9.76005 11.66C9.5741 11.8458 9.42658 12.0664 9.32593 12.3092C9.22529 12.552 9.17348 12.8122 9.17348 13.075C9.17348 13.3379 9.22529 13.5981 9.32593 13.8409C9.42658 14.0837 9.5741 14.3043 9.76005 14.49C10.1425 14.8515 10.6488 15.0529 11.1751 15.0529C11.7013 15.0529 12.2076 14.8515 12.5901 14.49ZM19.0001 15.19L20.3601 13.78L18.2901 11.66L16.8801 13.07L19.0001 15.19ZM11.1701 20.0504C13.0267 20.0504 14.8072 19.3129 16.1201 18L17.5401 16.61L15.4101 14.49L14.0001 15.9C13.2495 16.6506 12.2315 17.0723 11.1701 17.0723C10.6445 17.0723 10.124 16.9688 9.63847 16.7676C9.1529 16.5665 8.71169 16.2717 8.34005 15.9C7.96841 15.5284 7.67361 15.0872 7.47248 14.6016C7.27135 14.1161 7.16783 13.5956 7.16783 13.07C7.16783 12.0086 7.58949 10.9906 8.34005 10.24L9.80005 8.79005L7.68005 6.66005L6.22005 8.10005C4.90723 9.41287 4.1697 11.1934 4.1697 13.05C4.1697 14.9067 4.90723 16.6872 6.22005 18C7.53288 19.3129 9.31344 20.0504 11.1701 20.0504Z",
    fill: "none"
  }), _jsx("path", {
    d: "M14.9814 6.19793C14.9435 6.39169 14.849 6.56988 14.71 6.71006C14.617 6.80379 14.5064 6.87818 14.3846 6.92895C14.2627 6.97972 14.132 7.00586 14 7.00586C13.868 7.00586 13.7373 6.97972 13.6154 6.92895C13.4936 6.87818 13.383 6.80379 13.29 6.71006C13.151 6.56988 13.0565 6.39169 13.0186 6.19793C12.9807 6.00418 13.001 5.80353 13.0769 5.62128C13.1528 5.43903 13.281 5.28334 13.4453 5.17382C13.6096 5.0643 13.8026 5.00586 14 5.00586C14.1974 5.00586 14.3904 5.0643 14.5547 5.17382C14.719 5.28334 14.8472 5.43903 14.9231 5.62128C14.999 5.80353 15.0193 6.00418 14.9814 6.19793Z",
    fill: "none"
  })]
}), 'AttractingIcon', 'Brandbook');
export default AttractingIcon;