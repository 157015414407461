/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var GraphsFlowChartDown = createIconsComponent(_jsx("svg", {
  width: 33,
  height: 32,
  viewBox: "0 0 33 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.3946 2.17296C12.7901 2.17296 12.3 2.66305 12.3 3.26761V9.14414C12.3 9.7487 12.7901 10.2388 13.3946 10.2388H19.2711C19.8757 10.2388 20.3658 9.7487 20.3658 9.14414V3.26761C20.3658 2.66305 19.8757 2.17296 19.2711 2.17296H13.3946ZM17.1971 11.9672H19.2711C20.8303 11.9672 22.0942 10.7033 22.0942 9.14414V3.26761C22.0942 1.70848 20.8303 0.444565 19.2711 0.444565H13.3946C11.8355 0.444565 10.5716 1.70848 10.5716 3.26761V9.14414C10.5716 10.7033 11.8355 11.9672 13.3946 11.9672H15.4687V16.1153H8.4975C7.74878 16.1153 7.03073 16.4128 6.50131 16.9422C5.97188 17.4716 5.67446 18.1896 5.67446 18.9384V20.033H3.60038C2.04126 20.033 0.777344 21.2969 0.777344 22.8561V28.7326C0.777344 30.2917 2.04126 31.5556 3.60038 31.5556H9.47692C11.036 31.5556 12.3 30.2917 12.3 28.7326V22.8561C12.3 21.2969 11.036 20.033 9.47692 20.033H7.40285V18.9384C7.40285 18.648 7.51818 18.3696 7.72346 18.1643C7.92875 17.959 8.20718 17.8437 8.4975 17.8437H24.1683C24.4586 17.8437 24.737 17.959 24.9423 18.1643C25.1476 18.3696 25.2629 18.648 25.2629 18.9384V20.033H23.1888C21.6297 20.033 20.3658 21.2969 20.3658 22.8561V28.7326C20.3658 30.2917 21.6297 31.5556 23.1888 31.5556H29.0654C30.6245 31.5556 31.8884 30.2917 31.8884 28.7326V22.8561C31.8884 21.2969 30.6245 20.033 29.0654 20.033H26.9913V18.9384C26.9913 18.1896 26.6939 17.4716 26.1644 16.9422C25.635 16.4128 24.917 16.1153 24.1683 16.1153H17.1971V11.9672ZM23.1888 21.7614C22.5843 21.7614 22.0942 22.2515 22.0942 22.8561V28.7326C22.0942 29.3371 22.5843 29.8272 23.1888 29.8272H29.0654C29.6699 29.8272 30.16 29.3371 30.16 28.7326V22.8561C30.16 22.2515 29.6699 21.7614 29.0654 21.7614H23.1888ZM3.60038 21.7614C2.99583 21.7614 2.50574 22.2515 2.50574 22.8561V28.7326C2.50574 29.3371 2.99583 29.8272 3.60038 29.8272H9.47692C10.0815 29.8272 10.5716 29.3371 10.5716 28.7326V22.8561C10.5716 22.2515 10.0815 21.7614 9.47692 21.7614H3.60038Z",
    fill: "none"
  })
}), 'GraphsFlowChartDown', 'Brandbook');
export default GraphsFlowChartDown;