/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FocusIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.00004 6.59004L3.71004 2.29004L2.29004 3.71004L6.59004 8.00004H3.00004V10H10V3.00004H8.00004V6.59004Z",
    fill: "none"
  }), _jsx("path", {
    d: "M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z",
    fill: "none"
  }), _jsx("path", {
    d: "M17.41 8.00004H21V10H14V3.00004H16V6.59004L20.29 2.29004L21.71 3.71004L17.41 8.00004Z",
    fill: "none"
  }), _jsx("path", {
    d: "M21 16V14H14V21H16V17.41L20.29 21.71L21.71 20.29L17.41 16H21Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.59004 16H3.00004V14H10V21H8.00004V17.41L3.71004 21.71L2.29004 20.29L6.59004 16Z",
    fill: "none"
  })]
}), 'FocusIcon', 'Brandbook');
export default FocusIcon;