/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UserSettings = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.1564 2.28879C11.9059 2.47755 10.7393 3.06126 9.83409 3.96645C8.7172 5.08333 8.08975 6.59815 8.08975 8.17766C8.08975 9.75717 8.7172 11.272 9.83409 12.3889C10.7393 13.2941 11.9059 13.8778 13.1564 14.0665V2.28879ZM8.57701 2.70937C10.0273 1.25909 11.9943 0.444336 14.0453 0.444336C14.281 0.444336 14.5071 0.537986 14.6738 0.704685C14.8405 0.871384 14.9342 1.09748 14.9342 1.33322V15.0221C14.9342 15.513 14.5362 15.911 14.0453 15.911C11.9943 15.911 10.0273 15.0962 8.57701 13.6459C7.12673 12.1957 6.31197 10.2287 6.31197 8.17766C6.31197 6.12665 7.12673 4.15965 8.57701 2.70937Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.6735 17.3266C14.8404 17.4933 14.9342 17.7195 14.9342 17.9554V30.6665C14.9342 31.1574 14.5362 31.5554 14.0453 31.5554H1.3342C1.09829 31.5554 0.872065 31.4616 0.70534 31.2947C0.538615 31.1278 0.445071 30.9015 0.445313 30.6656C0.449005 27.06 1.88295 23.6032 4.43247 21.0537C6.98199 18.5042 10.4388 17.0702 14.0444 17.0665C14.2803 17.0663 14.5066 17.1598 14.6735 17.3266ZM13.1564 18.8787C10.3473 19.0933 7.69543 20.3049 5.68954 22.3108C3.68366 24.3166 2.47206 26.9685 2.25747 29.7776H13.1564V18.8787Z",
    fill: "none"
  }), _jsx("path", {
    d: "M18.8453 1.33322C18.8453 0.842304 18.4473 0.444336 17.9564 0.444336C17.4655 0.444336 17.0675 0.842304 17.0675 1.33322V4.25952L17.0675 4.26654C17.0675 4.63905 17.2966 4.95804 17.6216 5.09025C17.725 5.13228 17.838 5.15544 17.9564 5.15544L17.9603 5.15543C18.741 5.15645 19.4903 5.45949 20.0518 5.99981C20.0645 6.01432 20.0778 6.02851 20.0917 6.04236C20.1055 6.05621 20.1197 6.0695 20.1342 6.08224C20.6755 6.64473 20.9786 7.39556 20.9786 8.17765C20.9786 8.95981 20.6754 9.71071 20.1341 10.2732C20.1196 10.2859 20.1055 10.2992 20.0917 10.313C20.0779 10.3268 20.0646 10.3409 20.0519 10.3553C19.4904 10.8958 18.7411 11.1989 17.9603 11.1999L17.9564 11.1999C17.4655 11.1999 17.0675 11.5978 17.0675 12.0887V15.0221C17.0675 15.513 17.4655 15.911 17.9564 15.911C18.4473 15.911 18.8453 15.513 18.8453 15.0221V12.8946C19.4967 12.7719 20.1158 12.5154 20.6633 12.1416L22.2167 13.6951C22.5639 14.0422 23.1267 14.0422 23.4738 13.6951C23.8209 13.3479 23.8209 12.7851 23.4738 12.438L21.9203 10.8845C22.2941 10.3371 22.5506 9.71795 22.6734 9.06656H24.8008C25.2917 9.06656 25.6897 8.66859 25.6897 8.17767C25.6897 7.68675 25.2917 7.28878 24.8008 7.28878H22.6734C22.5506 6.63738 22.2941 6.0182 21.9203 5.47079L23.4738 3.91733C23.8209 3.57019 23.8209 3.00738 23.4738 2.66025C23.1267 2.31312 22.5639 2.31312 22.2167 2.66025L20.6633 4.2137C20.1159 3.8399 19.4967 3.58342 18.8453 3.46066V1.33322Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.0675 17.9554C17.0675 17.4645 17.4655 17.0665 17.9564 17.0665H19.9119C20.4029 17.0665 20.8008 17.4645 20.8008 17.9554V20.384C21.6461 20.6181 22.4589 20.9565 23.2205 21.3915L24.9266 19.6754C25.0931 19.5079 25.3195 19.4135 25.5557 19.4132C25.7919 19.4129 26.0185 19.5065 26.1855 19.6735L28.9494 22.4374C29.1161 22.6041 29.2097 22.8302 29.2097 23.0659C29.2097 23.3017 29.1161 23.5278 28.9494 23.6945L27.2416 25.4022C27.6738 26.1643 28.0095 26.9771 28.2409 27.8221H30.6675C31.1584 27.8221 31.5564 28.22 31.5564 28.711V30.6665C31.5564 31.1574 31.1584 31.5554 30.6675 31.5554C30.1766 31.5554 29.7786 31.1574 29.7786 30.6665V29.5999H27.5386C27.115 29.5999 26.7503 29.301 26.6671 28.8857C26.4434 27.7701 26.0051 26.7086 25.3766 25.7602C25.1431 25.4078 25.1901 24.9396 25.489 24.6407L27.0637 23.0659L25.5588 21.561L23.9841 23.1451C23.6845 23.4464 23.2135 23.4936 22.8601 23.2576C21.9126 22.6249 20.851 22.1826 19.7346 21.9553C19.3205 21.871 19.0231 21.5069 19.0231 21.0843V18.8443H17.9564C17.4655 18.8443 17.0675 18.4463 17.0675 17.9554Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.0675 25.7776C17.0675 25.2867 17.4655 24.8887 17.9564 24.8887C19.4887 24.8887 20.9583 25.4975 22.0419 26.581C23.1254 27.6645 23.7341 29.1341 23.7341 30.6665C23.7341 31.1574 23.3362 31.5554 22.8453 31.5554C22.3543 31.5554 21.9564 31.1574 21.9564 30.6665C21.9564 29.6056 21.5349 28.5882 20.7848 27.8381C20.0347 27.0879 19.0172 26.6665 17.9564 26.6665C17.4655 26.6665 17.0675 26.2685 17.0675 25.7776Z",
    fill: "none"
  })]
}), 'UserSettings', 'Brandbook');
export default UserSettings;